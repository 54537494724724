@if (isLoading) {
  <div class="table-loading-indicator">
    <div class="table-loading-indicator__container">
      <div class="table-loading-indicator__icon">
        <nxh-icon-loader />
        <div class="loading-text">
          {{ timeoutWarning ? ('_loading-states.warning-timeout' | i18next) : loadingMessage }}
        </div>
      </div>
    </div>
  </div>
}

@if (isEmpty && !(isLoading || isError)) {
  <div>
    @if (!emptyStateTemplate) {
      <nxh-empty-state [ngClass]="{ 'small-text': smallText }" icon="file-search">
        <div class="empty__title">{{ '_loading-states.no-items-found' | i18next }}</div>
        <div class="empty__description">
          {{ '_loading-states.no-items-found-for-query' | i18next }}
        </div>
      </nxh-empty-state>
    } @else {
      <ng-container [ngTemplateOutlet]="emptyStateTemplate!" />
    }
  </div>
}

@if (isError && !isLoading) {
  <div class="table-loading-error">
    @if (!errorStateTemplate) {
      <nxh-timeout-state [ngClass]="{ 'small-text': smallText }">
        <div class="timeout__title">{{ 'general-timeout-error-title' | i18next }}</div>
        <div class="timeout__description">{{ 'general-timeout-error-description' | i18next }}.</div>
      </nxh-timeout-state>
    } @else {
      <ng-container [ngTemplateOutlet]="errorStateTemplate!" />
    }
  </div>
}
