@if (!!parent) {
  <div>
    <div [formGroup]="parent">
      <div class="period__row__inputs" formGroupName="timeslot">
        @if (showLabel) {
          <div class="period__label period__label--from">{{ 'timing' | i18next }}</div>
        }
        <nh-control>
          <nxh-time-picker-input
            [useClockTime]="true"
            formControlName="start"
            [class.ng-invalid]="parent.get('timeslot')?.get('start')?.invalid"
          />
          <val-errors [label]="'timing' | i18next" />
        </nh-control>
      </div>
      <val-errors class="period__error" [control]="parent.get('timeslot')" />
    </div>
  </div>
}
