<div class="sort-header__container" (click)="sort()">
  <ng-content />

  @switch (sortByOrder) {
    @case ('asc') {
      <fa-duotone-icon
        [icon]="['fad', 'sort']"
        primaryOpacity="1"
        secondaryOpacity="1"
        primaryColor="var(--gray-600)"
        secondaryColor="var(--gray-400)"
      />
    }
    @case ('desc') {
      <fa-duotone-icon
        [icon]="['fad', 'sort']"
        primaryOpacity="1"
        secondaryOpacity="1"
        primaryColor="var(--gray-400)"
        secondaryColor="var(--gray-600)"
      />
    }
    @default {
      <fa-duotone-icon
        [icon]="['fad', 'sort']"
        primaryOpacity="1"
        secondaryOpacity="1"
        primaryColor="var(--gray-400)"
        secondaryColor="var(--gray-400)"
      />
    }
  }
</div>
