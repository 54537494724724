import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { PopoverComponent, PopoverContent, PopoverRef, POPOVER_CONTENT } from './popover.model';

@Component({
  template: `
    <div class="w-100 border rounded shadow bg-white popover-container">
      @switch (renderMethod) {
        @case ('text') {
          <div [innerHTML]="content"></div>
        }
        @case ('template') {
          <!-- context always exists with 'template' renderMethod-->
          <ng-container *ngTemplateOutlet="$any(content); context: context!"></ng-container>
        }
        @case ('component') {
          <ng-container *ngComponentOutlet="$any(content)"></ng-container>
        }
      }
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
      }

      .popover-container {
        height: 100%;
      }
    `,
  ],
})
export class SimplePopoverComponent implements OnInit, PopoverComponent {
  renderMethod: 'template' | 'component' | 'text' = 'component';
  context?: { close: PopoverRef['close'] };

  constructor(
    private popoverRef: PopoverRef,
    @Inject(POPOVER_CONTENT) public content: PopoverContent,
  ) {}

  ngOnInit() {
    if (typeof this.content === 'string') {
      this.renderMethod = 'text';
    }

    if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      this.context = {
        close: this.popoverRef.close.bind(this.popoverRef),
      };
    }
  }
}
