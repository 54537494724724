<div class="highlight-block" [formGroup]="parent">
  <div class="highlight-block--title">
    {{ title | i18next }}
  </div>
  <div class="dayparts-container">
    <nh-radio-group formControlName="allDayOrTime">
      <nh-radio [value]="false" [nxhDataTest]="'day-parts'">
        <div nxhOutsideLabel>
          <nxh-multi-toggle-list
            nxhDataTest="allDayParts"
            [formControl]="$any(parent.get('parts'))"
            [showCheckMarks]="false"
            [options]="partOptions"
            [pills]="true"
          >
            <ng-template nxhToggleListOption let-option>
              <div class="part-option">
                <div class="part-option__title">{{ option.label | i18next }}</div>
                <div class="part-option__subtitle">
                  {{ option.range.from | formatClockTime }} - {{ option.range.to | formatClockTime }}
                </div>
              </div>
            </ng-template>
          </nxh-multi-toggle-list>
          <!-- cannot use val-errors here because we check the parent control's invalid state and the child control's
          touched state-->
          @if (parent.hasError('_errors.day-parts-incomplete') && parent.get('parts')?.touched) {
            <div class="input-error">
              {{ '_errors.dayparts-incomplete' | i18next }}
            </div>
          }
        </div>
      </nh-radio>
      <nh-radio
        [nxhDataTest]="'all-day'"
        [noLabel]="!!parent.get('time')"
        [label]="!parent.get('time') ? ('_resource-planning._recurrence.whole-day' | i18next) : ''"
        [value]="true"
      >
        @if (parent.get('time')) {
          <nh-control nxhOutsideLabel [label]="'_care._care.desired-time' | i18next">
            <nxh-time-picker-input [formControl]="$any(parent.get('time'))" nxhDataTest="desired-time" />
            <val-errors />
          </nh-control>
        }
      </nh-radio>
    </nh-radio-group>
  </div>
</div>
