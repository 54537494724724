<button [disabled]="disabled" class="btn" [type]="btnType" [id]="id" [ngClass]="'btn-' + type" #button>
  @if (icon) {
    <fa-icon
      [ngClass]="{ spinner: loading }"
      [animation]="loading ? 'spin' : undefined"
      [icon]="loading ? 'spinner' : icon!"
    />
  } @else {
    @if (loading) {
      <fa-icon class="spinner" animation="spin" icon="spinner" />
    }
  }
  @if (showLabel) {
    <p>{{ label }}</p>
  }
</button>
