<div
  class="day-container"
  [class.start]="isRangeStart$ | async"
  [class.end]="isRangeEnd$ | async"
  [class.between]="isBetween$ | async"
  [class.focused]="focused"
  [class.disabled]="disabled"
  [class.hovered]="isHovered$ | async"
  [class.no-highlight-on-focused]="noHighlightOnFocused"
  [class.today]="showToday && (isToday$ | async)"
  [class.end-hovered]="isBackgroundEndHovered$ | async"
  [class.outside]="isOutside$ | async"
  [class.wontChangeOnNextDateSelection]="wontChangeOnNextDateSelection"
>
  <div class="day-text">{{ (date$$ | async)?.day }}</div>

  @if (bullet !== undefined) {
    <div class="day-status">
      <nxh-bullet
        class="mini-calendar__day__status bullet--small"
        [status]="bullet.status"
        [ngbTooltip]="bullet.tooltip"
      />
    </div>
  }
</div>
@if (showRangeBackground$ | async) {
  <div
    class="range-background"
    [class.range--start]="isBackgroundStart$ | async"
    [class.range--end]="(isBackgroundEnd$ | async) || (isBackgroundEndHovered$ | async)"
  ></div>
}
