<!--note: workaround to set one individual radio input disabled:
https://github.com/angular/angular/issues/11763#issuecomment-268622631-->

@if (formControl) {
  <div class="form-check">
    <input
      #input
      type="radio"
      class="form-check-input"
      [id]="id"
      [value]="value"
      [name]="name"
      [formControl]="formControl"
      [attr.disabled]="disabled === true || null"
      (blur)="onBlur()"
    />
    @if (!noLabel) {
      <label class="form-check-label" [for]="id">
        @if (label) {
          <span>{{ label }}</span>
        }
        <ng-content />
      </label>
    }
    <ng-content select="[nxhOutsideLabel]" />
  </div>
}
