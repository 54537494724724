import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfidentialitySelectComponent } from './confidentiality-select/confidentiality-select.component';
import { CustomizeNgSelectAddTagDirective } from './customize-ng-select-add-tag.directive';
import { CustomizeNgSelectCheckboxesDirective } from './customize-ng-select-checkboxes.directive';
import { CustomizeNgSelectDefaultsDirective } from './customize-ng-select-defaults.directive';
import { NgOptionHighlightDirective } from './ng-option-highlight.directive';
import { NgSelectErrorDirective } from './ng-select-error.directive';
import { NgSelectTemplatesComponent } from './ng-select-templates.component';
import { CheckboxOptionComponent } from './options/checkbox-option/checkbox-option.component';
import { LoadingOptionComponent } from './options/loading-option/loading-option.component';
import { NoResultOptionComponent } from './options/no-result-option/no-result-option.component';

@NgModule({
  imports: [
    CheckboxOptionComponent,
    NgOptionHighlightDirective,
    LoadingOptionComponent,
    ConfidentialitySelectComponent,
    NoResultOptionComponent,
    NgSelectErrorDirective,
    NgSelectTemplatesComponent,
    CustomizeNgSelectAddTagDirective,
    CustomizeNgSelectCheckboxesDirective,
    CustomizeNgSelectDefaultsDirective,
  ],
  exports: [
    CheckboxOptionComponent,
    LoadingOptionComponent,
    ConfidentialitySelectComponent,
    CustomizeNgSelectCheckboxesDirective,
    CustomizeNgSelectDefaultsDirective,
    NgSelectTemplatesComponent,
    NgSelectModule,
    NgOptionHighlightDirective,
    NgSelectErrorDirective,
    NoResultOptionComponent,
    CustomizeNgSelectAddTagDirective,
  ],
})
export class SelectsModule {}
