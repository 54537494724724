@if (loadingState && loadingState !== 'loaded') {
  <nxh-loading-states
    [loadingState]="loadingState"
    [emptyTemplate]="emptyTemplate"
    [errorTemplate]="errorTemplate"
    [loadingTemplate]="loadingTemplate"
  />
}
<div class="modal-body__content">
  <ng-content />
</div>
