import { Component, HostBinding, Input } from '@angular/core';
import { IconProp } from '@fortawesome/angular-fontawesome/types';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';

@Component({
  selector: 'nxh-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  standalone: true,
  imports: [IconsModule],
})
export class EmptyStateComponent {
  private _icon: IconProp = ['fas', 'folder-open'];

  @HostBinding('class.small-text')
  @Input()
  smallText = false;

  @Input()
  set icon(value: IconProp) {
    if (typeof value === 'string') {
      this._icon = ['fas', value];
    } else {
      this._icon = value;
    }
  }

  get icon() {
    return this._icon;
  }
}
