@switch (status$ | async) {
  @case ('loading') {
    <div class="page-loading-indicator">
      <div class="page-loading-indicator__container">
        <div class="page-loading-indicator__icon">
          <nxh-icon-loader />
        </div>
        <div class="page-loading-indicator__description">
          {{ timeoutWarning ? ('_loading-states.warning-timeout' | i18next) : loadingMessage }}
        </div>
      </div>
    </div>
  }
  @case ('empty') {
    <div>
      @if (!emptyStateTemplate) {
        <nxh-empty-state icon="file-search">
          <div class="empty__title">{{ '_loading-states.no-items-found' | i18next }}</div>
          <div class="empty__description">
            {{ '_loading-states.no-items-found-for-query' | i18next }}
          </div>
        </nxh-empty-state>
      } @else {
        <ng-container [ngTemplateOutlet]="emptyStateTemplate!" />
      }
    </div>
  }
  @case ('error') {
    <div class="page-loading-error">
      @if (!errorStateTemplate) {
        <nxh-timeout-state>
          <div class="timeout__title">{{ 'general-timeout-error-title' | i18next }}</div>
          <div class="timeout__description">{{ 'general-timeout-error-description' | i18next }}.</div>
        </nxh-timeout-state>
      } @else {
        <ng-container [ngTemplateOutlet]="errorStateTemplate!" />
      }
    </div>
  }
}
