import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PopoverPosition, PopoverRef, PopoverService } from '@nexuzhealth/shared-ui-toolkit/popover';
import { IconProp } from '@fortawesome/angular-fontawesome/types';
import { ConfirmComponent } from './confirm.component';
import { ConfirmService } from './confirm.service';
import { ConfirmType } from './confirm.model';

@Directive({
  selector: '[nxhConfirm]',
  exportAs: 'confirm',
})
export class ConfirmDirective implements OnChanges, OnInit, OnDestroy {
  @Input() busy: boolean | null = null;
  @Input() origin!: ElementRef | HTMLElement;
  @Input() showConfirm = true;
  @Input() confirmText = '_confirm.title';
  @Input() confirmType: ConfirmType = 'danger';
  @Input() confirmIcon: IconProp = 'hexagon-exclamation';
  @Input() confirmBody: string | undefined = undefined;
  @Input() buttonText = 'delete';
  @Input() disableConfirm = false;
  @Input() positions: PopoverPosition[] | undefined = undefined;
  /**
   * Immediately calls (confirm), bypassing the actual confirm check
   */
  @Input() bypassConfirm = false;

  @Output() confirm = new EventEmitter<void>();
  isOpen$ = new BehaviorSubject<boolean>(false);

  private ref?: PopoverRef;
  private destroy$ = new Subject<void>();

  constructor(
    private el: ElementRef,
    private popper: PopoverService,
    private confirmService: ConfirmService,
  ) {}

  private get hasBusyIndicator() {
    return this.busy !== null;
  }

  ngOnInit(): void {
    this.confirmService.closeAll$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.ref?.close();
    });
  }

  @HostListener('click', ['$event'])
  clickEvent($event: MouseEvent) {
    if (this.bypassConfirm) {
      this.confirm.emit();
      return;
    }

    if (!this.showConfirm || this.disableConfirm) {
      return;
    }

    $event.preventDefault();
    $event.stopPropagation();

    const config = {
      hasBackdrop: false,
      component: ConfirmComponent,
      origin: this.origin ? this.origin : this.el,
      positions: this.positions,
      data: {
        confirmText: this.confirmText,
        confirmBody: this.confirmBody,
        showBusyIndicator: this.hasBusyIndicator,
        type: this.confirmType,
        buttonText: this.buttonText,
        icon: this.confirmIcon,
      },
    };

    const actions = {
      confirm: (result: any) => {
        if (this.hasBusyIndicator) {
          this.confirm.emit(result);
        } else {
          this.confirm.emit(result);
          this.ref?.close();
        }
      },
    };
    this.ref = this.popper.open(config, actions);

    this.isOpen$.next(true);
    this.ref.result$.subscribe({ complete: () => this.isOpen$.next(false) });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change: SimpleChange = changes['busy'];
    if (this.hasBusyIndicator && change && !change.isFirstChange() && change.currentValue === false && this.ref) {
      this.ref.close();
    }
  }

  close() {
    this.ref?.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    // should somehow the originating button be remove (e.g. when the button is part of a table row, and the
    // table is redrawn as a result of the delete action) we'll force close the confirm popup
    if (this.ref) {
      this.ref.close();
    }
  }
}

@Directive({
  selector: '[nxhConfirmInfo]',
  exportAs: 'confirmInfo',
})
export class ConfirmInfoDirective extends ConfirmDirective {
  @Input() override confirmText = '_confirm-info.title';
  @Input() override buttonText = '_confirm-info.submit';
  override confirmIcon = 'info-circle' as const;
  override confirmType = 'primary' as const;
}

@Directive({
  selector: '[nxhConfirmWarning]',
  exportAs: 'confirmWarning',
})
export class ConfirmWarningDirective extends ConfirmDirective {
  @Input() override confirmText = '_confirm-warning.title';
  @Input() override buttonText = '_messages.send';
  override confirmIcon = 'triangle-exclamation' as const;
  override confirmType = 'warning' as const;
}
