<div class="chart-titles">
  <p class="sub-title">
    @if (title) {
      <span>{{ title | i18next }}</span>
    }
    @if (titleUnit) {
      <span>{{ titleUnit }}</span>
    }
  </p>
  @if (subTitle) {
    <p class="small-title">{{ subTitle | i18next }}</p>
  }
</div>

<canvas #canvas></canvas>
