import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { isEqual } from 'lodash-es';
import { combineLatest, concat, isObservable, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import {
  Breadcrumb,
  BreadcrumbItemConfig,
  BreadcrumbRouteConfigData,
  BreadcrumbSnapshotData,
} from './breadcrumb.domain';
import { isObservableArray } from './breadcrumb.util';

// inspired by https://medium.com/@bo.vandersteene/angular-5-breadcrumb-c225fd9df5cf
@Component({
  selector: 'nxh-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, RouterLink],
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs$!: Observable<Breadcrumb[]>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
  ) {}

  ngOnInit() {
    // on load we might have missed the router event
    const init$ = of(true);

    const routeEvents$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
    );

    this.breadcrumbs$ = concat(init$, routeEvents$).pipe(
      map(() => this.getAllBreadcrumbObservables(this.activatedRoute.root)),
      switchMap((observables) => combineLatest(observables)),
      distinctUntilChanged(compareSnapshots),
      fixUrls(),
    );
  }

  private getAllBreadcrumbObservables(
    route: ActivatedRoute,
    breadcrumbs: Array<Observable<BreadcrumbItemConfig>> = [],
  ): Observable<BreadcrumbItemConfig>[] {
    const breadcrumbs$ = this.getBreadcrumbObservable(route);

    // don't show breadcrumbs with empty labels
    const newBreadcrumbs = breadcrumbs$
      ? breadcrumbs$ instanceof Array
        ? [...breadcrumbs, ...breadcrumbs$]
        : [...breadcrumbs, breadcrumbs$]
      : breadcrumbs;

    if (route.firstChild) {
      //If we are not on our current path yet, there will be more children to look after, to build our breadcumb
      return this.getAllBreadcrumbObservables(route.firstChild, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  private getBreadcrumbObservable(
    route: ActivatedRoute,
  ): Observable<BreadcrumbItemConfig> | Array<Observable<BreadcrumbItemConfig>> | null {
    if (!route.routeConfig) {
      return of({ label: this.translate('home'), path: '' });
    }

    const routeConfigData = route.routeConfig.data as BreadcrumbRouteConfigData;
    if (routeConfigData) {
      const { skipBreadcrumb, breadcrumb, breadcrumbClassnames } = routeConfigData;
      if (skipBreadcrumb) {
        return null;
      }

      if (typeof breadcrumb === 'string') {
        return of({
          label: this.translate(breadcrumb),
          path: route.routeConfig.path,
          classnames: breadcrumbClassnames || '',
        });
      }
      if (Array.isArray(breadcrumb)) {
        return breadcrumb.map((item) =>
          of({
            ...item,
            label: this.translate(item.label),
          }),
        );
      }
    }

    // processing 'breadcrumb resolvers', e.g. resolve: { breadcrumb: SubcontactBreadcrumbResolveService }
    // if these need to be translated, this should be taken care of in the resolver
    const snapshotData = route.snapshot.data as BreadcrumbSnapshotData;
    if (snapshotData) {
      const { breadcrumb } = snapshotData;
      // only return when we have an observable here, as other cases have been taken care of when processing
      // BreadcrumbRouteConfigData
      if (breadcrumb && (isObservable(breadcrumb) || isObservableArray(breadcrumb))) {
        return breadcrumb;
      }
    }

    // path variables are not translated
    const isPathVariable = route.routeConfig.path?.startsWith(':');
    if (isPathVariable) {
      // Suppressed - we have checked before that route.routeConfig.path exists
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const pathVariable = route.snapshot.paramMap.get(route.routeConfig.path!.substr(1));
      // Suppressed should pathVariable be null than navigating to this route would go wrong
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return of({ label: `#${pathVariable}`, path: pathVariable! });
    }

    // path names
    const pathLabel = route.routeConfig.path ? this.translate(route.routeConfig.path) : '';
    return of({ label: pathLabel, path: route.routeConfig.path });
  }

  private translate(key: string) {
    return this.i18Next.t(key);
  }
}

function fixUrls() {
  return map((snapshots: BreadcrumbItemConfig[]) => {
    return snapshots.reduce((prev, curr) => {
      if (!curr.label) {
        return prev;
      }

      let path: string[] = [];
      switch (typeof curr.path) {
        case 'string':
          path = [curr.path];
          break;
        case 'object':
          path = curr.path;
          break;
      }

      const url: string[] = prev.length > 0 ? [...prev[prev.length - 1].url, ...path] : [...path];
      const bc = {
        label: curr.label,
        url,
        classnames: curr.classnames || '',
        extraUrl: curr.extraPath ? [...url, curr.extraPath] : undefined,
      };
      return [...prev, bc];
    }, [] as Breadcrumb[]);
  });
}

function compareSnapshots(prev: BreadcrumbItemConfig[], current: BreadcrumbItemConfig[]) {
  return isEqual(
    prev.map((item) => item.label),
    current.map((item) => item.label),
  );
}
