@if (status) {
  <div class="bullet-container">
    <div
      class="bullet-point bullet--{{ status | lowercase }}"
      [ngbTooltip]="text | i18next"
      [disableTooltip]="!showStatusAsTooltip"
      [openDelay]="700"
      placement="top-left"
    ></div>
    @if (title || showStatusAsText) {
      <div class="bullet-text-container">
        @if (showStatusAsText) {
          <div class="bullet-text bullet-title">
            {{ text | i18next }}
          </div>
        }
        @if (title) {
          <div class="bullet-text bullet-title">
            {{ title }}
          </div>
        }
        @if (description) {
          <div class="bullet-text bullet-description">
            {{ description }}
          </div>
        }
        @if (bulletDescriptionRef) {
          <div class="bullet-text">
            <ng-container [ngTemplateOutlet]="bulletDescriptionRef.template" />
          </div>
        }
      </div>
    }
    <div class="bullet-text">
      <ng-content />
    </div>
    <ng-template />
  </div>
}
