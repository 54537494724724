<div class="form-group checkbox-group" [class.checkbox-group--compact]="compact">
  @if (label) {
    <label
      class="checkbox-group__label"
      [class.required]="required"
      [class.disabled]="disabledSubj | async"
      [for]="'checkbox-group-' + id"
      >{{ label }}
    </label>
  }
  <div
    class="checkbox-group__container"
    [class.checkbox-group--row]="direction === 'row'"
    [class.checkbox-group--col]="direction === 'col'"
  >
    <ng-content select="nh-checkbox" />
  </div>
  <div>
    <ng-content />
  </div>
</div>
