import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'nxh-dl-val',
  template: `<ng-content></ng-content>`,
  styles: [
    `
      :host {
        flex: 1;
        word-break: break-word;

        &:empty::after,
        > *:first-child:empty::after {
          content: '-';
          color: var(--gray-500);
        }

        &--empty::after {
          content: '-';
          color: var(--gray-500);
        }
      }
    `,
  ],
})
export class DataListValComponent {
  @HostBinding('class.data-list__value') clazz = true;
  @HostBinding('class.first-letter-capitalized') firstLetterCapitalized = true;
}
