import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export type Status = 'success' | 'warning' | 'danger' | 'info' | 'primary' | 'neutral';
export type CounterPosition = 'left' | 'right' | 'top-right';

@Component({
  selector: 'nxh-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterComponent {
  // -1 due to storybook bug
  @Input() value = -1;
  @Input() startShowingAtValue = 0; // show the badge if the value is above or equal to this number, default show always = 0
  @Input() maxDigits = -1;
  @Input() position: CounterPosition = 'top-right';
  @Input() statusMap: Partial<{ [key in Status]: number }> = { danger: 0 };
  @Input() compact = false;

  @HostBinding('class.left') get left() {
    return this.position === 'left';
  }

  @HostBinding('class.right') get right() {
    return this.position === 'right';
  }

  @HostBinding('class.top-right') get topRight() {
    return this.position === 'top-right';
  }

  get status(): string | undefined {
    const sorted = Object.entries(this.statusMap).sort((left, right) => right[1] - left[1]);
    return sorted.find((entry) => this.value >= entry[1])?.[0];
  }

  get shorthandValue(): string | number | undefined {
    if (this.value === undefined || this.value === null || this.value === -1) {
      return undefined;
    }

    if (this.value < this.startShowingAtValue) {
      return undefined;
    }

    if (!this.maxDigits || this.maxDigits === -1) {
      return this.value;
    }

    const threshold = Math.pow(10, this.maxDigits) - 1;
    return this.value > threshold ? `${threshold}+` : this.value;
  }
}
