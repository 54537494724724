import { Component, ContentChild, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormRecord, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { NgTemplateOutlet } from '@angular/common';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { I18NextModule } from 'angular-i18next';
import { ToggleListOption, ToggleListOptionDirective } from '../toggle-list-option.directive';
import { ToggleGroupDirective } from '../toggle-group.directive';
import { ToggleOptionDirective } from '../toggle-option.directive';

@Component({
  selector: 'nxh-multi-toggle-list',
  templateUrl: './multi-toggle-list.component.html',
  styleUrls: ['./multi-toggle-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiToggleListComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ToggleGroupDirective,
    SharedTechFeatureE2eModule,
    NgTemplateOutlet,
    I18NextModule,
    ToggleOptionDirective,
  ],
})
export class MultiToggleListComponent<K> implements OnInit, ControlValueAccessor {
  private _options: ToggleListOptionViewObject<K>[] = [];
  @Input({ required: true }) set options(options: ToggleListOption<K>[]) {
    this._options = mapToToggleListOptionViewObjects(options);
  }
  get options(): ToggleListOptionViewObject<K>[] {
    return this._options;
  }

  @Input() pills = false;
  @Input() showCheckMarks = true;
  @ContentChild(ToggleListOptionDirective) optionDirective?: ToggleListOptionDirective;
  id = `item-${Math.random()}`;

  form = new FormRecord<FormControl<boolean>>({});

  ngOnInit() {
    this.options.forEach((option) => {
      this.form.addControl(
        option.valueAsString,
        new FormControl({ value: false, disabled: Boolean(option.disabled) }, { nonNullable: true }),
      );
    });
  }

  registerOnChange(fn: (value: K[]) => void): void {
    this.form.valueChanges.subscribe(() => fn(this.valueAsList()));
  }

  registerOnTouched(fn: () => void): void {
    this.form.valueChanges.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }

    // as form.disable and form.enable affects all child controls, we have to set the previously disabled controls
    // disabled again
    this.options.forEach((option) => {
      if (option.disabled) {
        this.form.get(option.valueAsString)?.disable({ emitEvent: false });
      }
    });
  }

  writeValue(values?: K[]): void {
    const valuesAsString = values?.map((value) => String(value)) ?? [];
    Object.entries(this.form.controls).forEach(([name, control]) => {
      control.setValue(Boolean(valuesAsString.indexOf(name) > -1), { emitEvent: false });
    });
  }

  private valueAsList(): K[] {
    return this.options.filter((option) => this.form.get(option.valueAsString)?.value).map((option) => option.value);
  }
}

type ToggleListOptionViewObject<K> = ToggleListOption<K> & { valueAsString: string };

// Note: can't be used as an input transform because input transforms do not support type annotations
function mapToToggleListOptionViewObjects<T>(options: ToggleListOption<T>[]): ToggleListOptionViewObject<T>[] {
  return options.map((option) => ({ ...option, valueAsString: String(option.value) }));
}
