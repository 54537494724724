<nxh-recurrence-period [parent]="parent" type="daily" />

@if (useTimeslots) {
  <nxh-time-periods
    [parent]="dayFormGroup"
    [addIconTooltip]="'_resource-planning._recurrence.add' | i18next"
    [removeIconTooltip]="'_resource-planning._recurrence.remove' | i18next"
  />
}

@if (dayFormGroup?.get('dayparts'); as daypartsFormGroup) {
  <nxh-dayparts
    [parent]="$any(daypartsFormGroup)"
    [partOptions]="dayparts"
    [useDayPartAllDay]="useDayPartAllDay"
    [validateDayPartOrTime]="validateDayPartOrTime"
  />
}
