import { Component, HostBinding, inject, Input } from '@angular/core';
import { DataListService } from './data-list.service';
import { KeySize } from './key-size.model';

@Component({
  selector: 'nxh-dl-key',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        flex: 1;
        color: var(--gray-600);
      }

      :host.data-list--key-size-sm {
        flex: 2;
      }

      :host.data-list--key-size-auto {
        flex: 0;
      }

      :host.data-list--key-size-auto ::ng-deep fa-icon {
        display: block;
        color: var(--gray-500);
        margin-right: 1rem;
        font-size: 1rem;
        width: 1.5rem;
        text-align: center;
        line-height: 1;
      }
    `,
  ],
  host: { role: 'listitem' },
})
export class DataListKeyComponent {
  @Input() keySize: KeySize | null = null;

  @HostBinding('class.data-list__key') clazz = true;
  @HostBinding('class.first-letter-capitalized') firstLetterCapitalized = true;

  private dataListService = inject(DataListService);

  @HostBinding('class.data-list--key-size-sm') get smallKey() {
    const keySize = this.keySize || this.dataListService.keySize;
    return keySize === 'S' || keySize === 'sm';
  }

  @HostBinding('class.data-list--key-size-auto') get iconKey() {
    const keySize = this.keySize || this.dataListService.keySize;
    return keySize === 'XS' || keySize === 'auto';
  }
}
