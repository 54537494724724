<div style="position: initial">
  <div
    ngbDropdown
    #dropdown="ngbDropdown"
    [container]="container"
    class="hide-default-down-icon"
    [ngClass]="dropdown.isOpen() ? 'ellipsis' : ''"
    (openChange)="dropdownChangedEvent($event)"
    (click)="$event.stopPropagation()"
  >
    <button
      nxh-button
      [buttonType]="smallIcon ? 'icon-small' : 'icon'"
      (click)="showDropdown = false"
      [ngbTooltip]="'more-actions' | i18next"
      nxhDataTest="dropdownConfig"
      ngbDropdownToggle
    >
      <fa-icon [icon]="icon" />
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownConfig" class="list-item__dropdown">
      @if (!showDropdown) {
        <div>
          <ng-content select="[ellipsisFirst]" />
          @if (editOption) {
            <button ngbDropdownItem (click)="dropdown.close(); edit()">
              <fa-icon icon="pen" />
              <div>{{ 'edit' | i18next }}</div>
            </button>
          }
          @if (editLink) {
            <button ngbDropdownItem [routerLink]="editLink" (click)="dropdown.close()">
              <fa-icon icon="pen" />
              <div>{{ 'edit' | i18next }}</div>
            </button>
          }
          <ng-content select="[ellipsisAfterEdit]" />
          @if (deleteOption) {
            <button
              ngbDropdownItem
              nxhConfirm
              [confirmText]="'confirm-delete%item' | i18next: { item: placeholder | i18next }"
              [busy]="loading"
              (confirm)="delete()"
              [bypassConfirm]="bypassConfirm"
            >
              <fa-icon icon="trash-alt" class="text-danger" />
              <div>{{ 'delete' | i18next }}</div>
            </button>
          }
          <ng-content />
        </div>
      }
    </div>
  </div>
</div>
