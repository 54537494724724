<div [formGroup]="form">
  <div class="nh-row row__select-recurrence">
    <nxh-single-toggle-list [formControl]="selectRecurrence" [options]="tabs">
      <ng-template nxhToggleListOption let-option>
        <span [nxhDataTest]="'select-' + option.label">
          {{ (option.label !== 'manual' ? option.label : '_resource-planning._recurrence.manual') | i18next }}</span
        >
      </ng-template>
    </nxh-single-toggle-list>
    <div class="nh-col"></div>
  </div>

  <div class="selected-tab-container">
    @switch (currentTabType$ | async) {
      @case ('daily') {
        <nxh-recurrence-tab-day [parent]="activeRecurrenceGroup" />
      }
      @case ('weekly') {
        <nxh-recurrence-tab-week [parent]="activeRecurrenceGroup" />
      }
      @case ('monthly') {
        <nxh-recurrence-tab-month [parent]="activeRecurrenceGroup" />
      }
      @case ('manual') {
        <nxh-recurrence-tab-manual [parent]="timingsArray" />
      }
    }
  </div>
</div>
