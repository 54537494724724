import { Component, Input, OnInit } from '@angular/core';
import { Environment, EnvironmentsService } from '@nexuzhealth/shared-util';
import { ThemeQuery } from '@nexuzhealth/shared/toolkit/feature-theming';

@Component({
  selector: 'nxh-nav-item-home',
  templateUrl: './nav-item-home.component.html',
  styleUrls: ['./nav-item-home.component.scss'],
})
export class NavItemHomeComponent implements OnInit {
  @Input() logoSize: 'sm' | 'md' = 'sm';
  @Input() homeLink: string[] | undefined | null;
  // HN-18252: the "pill" parts of this component should be extracted into a separate component, so it can be shared with the login-portal
  environment: string;
  substage: string;

  constructor(
    private environmentsService: EnvironmentsService,
    public themeQuery: ThemeQuery,
  ) {}

  ngOnInit() {
    if (this.environmentsService.environment !== Environment.PRD) {
      this.environment = this.environmentsService.environmentFull;
      this.substage = this.environmentsService.substage;
    }
  }
}
