<div class="alert__title" [class.alert__title--no-capitalization]="noCapitalization">
  @if (alertTitle) {
    <div>{{ alertTitle }}</div>
  }
  <ng-content />
  @if (closeable) {
    <button class="close-alert" nxh-button buttonType="subtle-button-small" (click)="closeAlert.emit()">
      <fa-icon [icon]="'times'" />
    </button>
  }
</div>
