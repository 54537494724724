@switch (!!controlComponent) {
  @case (true) {
    <div class="number-field has-control-component">
      @if (prefix) {
        <span class="prefix">{{ prefix }}</span>
      }
      <input
        #inputCC
        class="form-control"
        [class.nxh-align-left]="alignment === 'left'"
        [class.nxh-align-right]="alignment === 'right'"
        [class.has-prefix]="prefix"
        [class.has-suffix]="suffix"
        (input)="onInput($event)"
        (blur)="onBlur($event)"
        type="number"
        name="number-field"
        [step]="step"
        [disabled]="disabled"
        [placeholder]="placeholder || ''"
        [min]="min"
        [max]="max"
        [(ngModel)]="value"
      />
      @if (suffix) {
        <span class="suffix">{{ suffix }}</span>
      }
    </div>
  }
  @default {
    <div class="number-field" [class.no-label]="!label">
      @if (prefix) {
        <span class="prefix">{{ prefix }}</span>
      }
      <input
        #input
        class="form-control"
        [class.nxh-align-left]="alignment === 'left'"
        [class.nxh-align-right]="alignment === 'right'"
        [class.has-prefix]="prefix"
        [class.has-suffix]="suffix"
        (input)="onInput($event)"
        (blur)="onBlur($event)"
        nxhCompactFormControl=""
        [nxhControlError]="errorMessage"
        type="number"
        name="number-field"
        [step]="step"
        [disabled]="disabled"
        [label]="label"
        [placeholder]="placeholder || ''"
        [required]="required"
        [errorMap]="errorMap"
        [min]="min"
        [max]="max"
        [(ngModel)]="value"
      />
      @if (suffix) {
        <span class="suffix">{{ suffix }}</span>
      }
    </div>
  }
}
