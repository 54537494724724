import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Range } from '@nexuzhealth/shared-domain';
import { NgbDate, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerNavigateEvent } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker';
import { getNgbDateRange, jsDateToNgbDate, NgbDateRange, ngbDateToJsDate } from '../ngb-date-utils';
import { DayViewComponent } from '../day-view/day-view.component';

@Component({
  selector: 'nxh-mini-calendar',
  templateUrl: './mini-calendar.component.html',
  styleUrls: ['./mini-calendar.component.scss', '../datepicker-ngb.scss'],
  standalone: true,
  imports: [NgbDatepicker, DayViewComponent],
})
export class MiniCalendarComponent {
  @Input() mode: 'single' | 'range' = 'single';

  /**
   * Prevents highlighting cells that are in range when starting a range by clicking a startDate and hovering to an
   * toDate, as this *might* impact performance (all cells need to be rerendered at each hover). This property is
   * only relevant for mode 'range'
   */
  @Input() noHighlightInRangeOnHover = false;

  /**
   * Prevents highlighting the focused cell. This could be handy in the rare case when you want to highlight a
   * 'range' (e.g. of a whole week) on clicking a particular date. Only relevant for mode 'single'.
   */
  @Input() noHighlightOnFocused = false;

  @Output() dateSelection = new EventEmitter<Date | null>();
  @Output() rangeSelection = new EventEmitter<Partial<Range>>();
  @Output() navigate = new EventEmitter<Date | null>();

  @ViewChild(NgbDatepicker, { static: true }) datepicker!: NgbDatepicker;

  hoveredDate: NgbDate | null = null;
  private _bullets: MiniBullet[] = [];
  ngbDateRange?: NgbDateRange | null;

  @Input() set bullets(bullets: { date: Date; status: string; tooltip?: string }[]) {
    this._bullets = bullets?.map((bullet) => ({ ...bullet, date: jsDateToNgbDate(bullet.date) })) ?? [];
  }

  @Input() set range(range: Partial<Range>) {
    this.ngbDateRange = range
      ? {
          fromDate: range.fromDate ? jsDateToNgbDate(range.fromDate) : null,
          toDate: range.toDate ? jsDateToNgbDate(range.toDate) : null,
        }
      : null;
  }

  navigateToMonth(number: number) {
    const { state, calendar } = this.datepicker;
    this.datepicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));
  }

  navigateToDate(date: Date) {
    const ngbDate = jsDateToNgbDate(date);
    this.datepicker.navigateTo(ngbDate);
  }

  selectDate(date: Date) {
    this.datepicker.focusDate(jsDateToNgbDate(date));
    this.datepicker.focusSelect();
  }

  getBullet(date: NgbDate) {
    return this._bullets?.find((bullet) => bullet.date.equals(date));
  }

  onDateSelection(date: NgbDate) {
    if (this.mode === 'range') {
      this.ngbDateRange = getNgbDateRange(date, this.ngbDateRange);
      this.rangeSelection.emit({
        // for some reason we get a "The call would have succeeded against this implementation, but implementation
        // signatures of overloads are not externally visible." exception when passing parsed to ngbDateToJsDate
        // without first checking for null
        fromDate: this.ngbDateRange.fromDate ? ngbDateToJsDate(this.ngbDateRange.fromDate) : undefined,
        toDate: this.ngbDateRange.toDate ? ngbDateToJsDate(this.ngbDateRange.toDate) : undefined,
      });
    } else {
      this.dateSelection.emit(ngbDateToJsDate(date));
    }
  }

  onMouseEnter(date: NgbDate) {
    if (this.mode === 'range' && !this.noHighlightInRangeOnHover) this.hoveredDate = date;
  }

  onMouseLeave() {
    if (this.mode === 'range' && !this.noHighlightInRangeOnHover) this.hoveredDate = null;
  }

  onNavigate(event: NgbDatepickerNavigateEvent) {
    if (event.current !== null) {
      this.navigate.emit(ngbDateToJsDate(new NgbDate(event.next.year, event.next.month, 1)));
    }
  }
}

interface MiniBullet {
  date: NgbDate;
  status: string;
  tooltip?: string;
}
