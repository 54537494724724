<input
  class="datepicker"
  #input
  bsDatepicker
  [minDate]="minDate || undefined"
  [maxDate]="maxDate || undefined"
  [minMode]="mode"
  [bsConfig]="bsConfig"
  (bsValueChange)="onDateSelect($event)"
  nxhCompactFormControl=""
  [label]="label ? (label | i18next) : ''"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [required]="required"
  [nxhControlError]="catchAllErrorMessage"
  [errorMap]="errorMap"
  autocomplete="off"
  (change)="resetIfInvalid($event)"
  (input)="setLastValue($event)"
  (onShown)="logShown()"
  (blur)="logBlur()"
  [datesEnabled]="datesEnabled"
/>
