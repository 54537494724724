import { Component, HostBinding, inject, Input } from '@angular/core';
import { IconProp } from '@fortawesome/angular-fontawesome/types';
import { DataListService } from './data-list.service';
import { KeySize } from './key-size.model';

@Component({
  selector: 'nxh-dl',
  template: `
    <ng-template #content><ng-content></ng-content></ng-template>

    @if (icon) {
      <nxh-dl-row>
        <nxh-dl-key [keySize]="'auto'">
          <fa-icon [icon]="icon" />
        </nxh-dl-key>
        <nxh-dl-val>
          <nxh-dl>
            <ng-container *ngTemplateOutlet="content" ngProjectAs="nxh-dl-row"></ng-container>
          </nxh-dl>
        </nxh-dl-val>
      </nxh-dl-row>
    }

    @if (!icon) {
      <ng-container *ngTemplateOutlet="content" ngProjectAs="nxh-dl-row"></ng-container>
    }
  `,
  providers: [DataListService],
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  host: { role: 'list' },
})
export class DataListComponent {
  @HostBinding('class.data-list') clazz = true;
  @Input() set keySize(value: KeySize) {
    this.dataListService.keySize = value;
  }
  @Input() icon?: IconProp;

  private dataListService = inject(DataListService);
}
