@for (item of items | slice: 0 : count; track item) {
  @if (customLabelTemplate) {
    <ng-container *ngTemplateOutlet="customLabelTemplate; context: { $implicit: item }" />
  } @else {
    <div class="ng-value">
      <span class="ng-value-label">{{ bindLabel ? label(item) : item }}</span>
      <span class="ng-value-icon right" (click)="clear.emit(item)">×</span>
    </div>
  }
}
@if (items.length > count) {
  <div class="ng-value">
    <span class="ng-value-label"
      >+{{ items.length - count }} {{ moreLabel ? moreLabel : ('_ng-select.more' | i18next) }}</span
    >
  </div>
}
