<div class="nav__logo" [ngClass]="'nav__logo--' + logoSize">
  <a class="logo-link" [routerLink]="homeLink ?? '/home'">
    <img [src]="themeQuery.logoUrl$ | async" />
    @if (environment) {
      <div class="environment-pill" [ngClass]="'environment-pill--' + environment" [class.has-substage]="!!substage">
        {{ environment + (substage ? '-' : '') + substage }}
      </div>
    }
  </a>
</div>
