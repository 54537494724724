@switch (view) {
  @case ('block') {
    <div>
      <content-loader
        [foregroundColor]="primaryColor"
        [backgroundColor]="secondaryColor"
        [foregroundOpacity]="+(2 / 1).toFixed(1)"
        [viewBox]="'0 0 1060 400'"
        [style]="{ width: '100%', height: '100%' }"
        [speed]="2"
      >
        <svg:rect x="25" y="5" rx="0" ry="0" width="1000" height="45" />
        <svg:rect x="25" y="80" rx="0" ry="0" width="500" height="30" />
        <svg:rect x="25" y="120" rx="0" ry="0" width="500" height="30" />
        <svg:rect x="25" y="160" rx="0" ry="0" width="500" height="30" />
        <svg:rect x="25" y="200" rx="0" ry="0" width="500" height="30" />
        <svg:rect x="25" y="240" rx="0" ry="0" width="500" height="30" />
        <svg:rect x="25" y="280" rx="0" ry="0" width="500" height="30" />
      </content-loader>
    </div>
  }
  @case ('patient-nav') {
    <content-loader
      [foregroundColor]="primaryColor"
      [backgroundColor]="secondaryColor"
      [foregroundOpacity]="0.5"
      [speed]="2"
      [style]="{ width: '100%', height: '100%' }"
      [viewBox]="'0 0 500 40'"
    >
      <svg:rect x="10" y="7" rx="6" ry="6" [attr.width]="150" height="24" />
      <svg:rect x="190" y="7" rx="6" ry="6" [attr.width]="120" height="24" />
      <svg:rect x="325" y="7" rx="6" ry="6" [attr.width]="120" height="24" />
      <svg:rect x="460" y="7" rx="6" ry="6" [attr.width]="15" height="24" />
      <svg:rect x="490" y="7" rx="6" ry="6" [attr.width]="10" height="24" />
    </content-loader>
  }
  @case ('avatar') {
    <div>
      <content-loader
        [foregroundColor]="primaryColor"
        [backgroundColor]="secondaryColor"
        [speed]="2"
        [viewBox]="'0 0 200 200'"
        [style]="{ width: '100%', height: '100%' }"
      >
        <svg:rect x="0" y="0" rx="0" ry="0" width="200" height="200" />
      </content-loader>
    </div>
  }
  @case ('calendar') {
    <div>
      <content-loader
        [speed]="2"
        [foregroundColor]="primaryColor"
        [backgroundColor]="secondaryColor"
        [viewBox]="'0 0 1460 620'"
        [style]="{ width: '100%', height: '100%' }"
      >
        <svg:rect x="0" y="0" rx="0" ry="0" width="200" height="100" />
        <svg:rect x="210" y="0" rx="0" ry="0" width="200" height="100" />
        <svg:rect x="420" y="0" rx="0" ry="0" width="200" height="100" />
        <svg:rect x="630" y="0" rx="0" ry="0" width="200" height="100" />
        <svg:rect x="840" y="0" rx="0" ry="0" width="200" height="100" />
        <svg:rect x="1050" y="0" rx="0" ry="0" width="200" height="100" />
        <svg:rect x="1260" y="0" rx="0" ry="0" width="200" height="100" />
        <svg:rect x="0" y="115" rx="0" ry="0" width="1460" height="10" />
        <svg:rect x="0" y="145" rx="0" ry="0" width="200" height="500" />
        <svg:rect x="210" y="145" rx="0" ry="0" width="200" height="500" />
        <svg:rect x="420" y="145" rx="0" ry="0" width="200" height="500" />
        <svg:rect x="630" y="145" rx="0" ry="0" width="200" height="500" />
        <svg:rect x="840" y="145" rx="0" ry="0" width="200" height="500" />
        <svg:rect x="1050" y="145" rx="0" ry="0" width="200" height="500" />
        <svg:rect x="1260" y="145" rx="0" ry="0" width="200" height="500" />
      </content-loader>
    </div>
  }
  <!-- DAYPLANNER SECTION -->
  @case ('dayplanner-header') {
    <div>
      <content-loader
        [speed]="2"
        [foregroundColor]="primaryColor"
        [backgroundColor]="secondaryColor"
        [viewBox]="'0 0 900 30'"
        [style]="{ width: '100%', height: '100%' }"
      >
        <svg:rect x="0" y="0" rx="0" ry="10" width="200" height="25" />
        <svg:rect x="570" y="0" rx="0" ry="10" width="150" height="25" />
        <svg:rect x="740" y="0" rx="0" ry="10" width="170" height="25" />
      </content-loader>
    </div>
  }
  @case ('latest-vital-param') {
    <div>
      <content-loader
        [speed]="2"
        [foregroundColor]="primaryColor"
        [backgroundColor]="secondaryColor"
        [viewBox]="'0 0 256 480'"
        [style]="{ width: '100%', height: '100%' }"
      >
        <svg:circle cx="17" cy="31" r="1" />
        <svg:circle cx="25" cy="41" r="19" />
        <svg:rect x="71" y="17" rx="0" ry="0" width="0" height="0" />
        <svg:rect x="71" y="117" rx="0" ry="0" width="86" height="11" />
        <svg:rect x="73" y="173" rx="0" ry="0" width="86" height="10" />
        <svg:rect x="207" y="28" rx="0" ry="0" width="34" height="10" />
        <svg:circle cx="27" cy="191" r="19" />
        <svg:circle cx="27" cy="266" r="19" />
        <svg:circle cx="29" cy="340" r="19" />
        <svg:circle cx="26" cy="114" r="19" />
        <svg:circle cx="30" cy="422" r="19" />
        <svg:rect x="73" y="192" rx="0" ry="0" width="86" height="10" />
        <svg:rect x="72" y="250" rx="0" ry="0" width="86" height="9" />
        <svg:rect x="72" y="268" rx="0" ry="0" width="86" height="10" />
        <svg:rect x="73" y="324" rx="0" ry="0" width="86" height="10" />
        <svg:rect x="74" y="343" rx="0" ry="0" width="86" height="10" />
        <svg:rect x="74" y="424" rx="0" ry="0" width="86" height="10" />
        <svg:rect x="73" y="408" rx="0" ry="0" width="86" height="10" />
        <svg:rect x="70" y="25" rx="0" ry="0" width="86" height="11" />
        <svg:rect x="71" y="101" rx="0" ry="0" width="86" height="9" />
        <svg:rect x="71" y="43" rx="0" ry="0" width="86" height="11" />
        <svg:rect x="210" y="254" rx="0" ry="0" width="34" height="10" />
        <svg:rect x="211" y="326" rx="0" ry="0" width="34" height="10" />
        <svg:rect x="208" y="409" rx="0" ry="0" width="34" height="10" />
        <svg:rect x="209" y="106" rx="0" ry="0" width="34" height="10" />
        <svg:rect x="205" y="171" rx="0" ry="0" width="31" height="9" />
      </content-loader>
    </div>
  }
  @case ('document-viewer-attachments') {
    <div>
      <content-loader
        [speed]="2"
        [foregroundColor]="primaryColor"
        [backgroundColor]="secondaryColor"
        [viewBox]="'0 0 900 20'"
        [style]="{ width: '100%', height: '100%' }"
      >
        <svg:rect x="740" y="6" rx="6" ry="6" width="160" height="8" />
      </content-loader>
    </div>
  }
}
