import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { LoadingStatesComponent } from '@nexuzhealth/shared-ui-toolkit/loading2';
import { ModalBodyComponent } from './modal-body/modal-body.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { ModalControlsComponent } from './modal-controls/modal-controls.component';

@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    NgbCollapseModule,
    SharedTechFeatureE2eModule,
    ButtonModule,
    LoadingStatesComponent,
  ],
  declarations: [ModalHeaderComponent, ModalBodyComponent, ModalFooterComponent, ModalControlsComponent],
  exports: [ModalHeaderComponent, ModalBodyComponent, ModalFooterComponent, ModalControlsComponent],
})
export class ModalModule {}
