import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { NgOptionHighlightDirective } from './ng-option-highlight.directive';
import { LoadingOptionComponent } from './options/loading-option/loading-option.component';
import { CheckboxOptionComponent } from './options/checkbox-option/checkbox-option.component';
import { NoResultOptionComponent } from './options/no-result-option/no-result-option.component';

@Component({
  template: `
    <div>
      <ng-template #addTagRef ng-tag-tmp let-searchTerm="searchTerm">
        <div class="ng-option--add-tag">
          <div>
            <span>{{
              '_typeahead.add-term%term' | i18next: { term: searchTerm, interpolation: { escapeValue: false } }
            }}</span>
          </div>
        </div>
      </ng-template>

      <ng-template #typeToSearchRef ng-typetosearch-tmp>
        <div class="ng-option ng-option--type-to-search">
          <div>
            <span>
              {{ '_typeahead.type-to-search-text%length' | i18next: { length: startSearchingAtLength } }}
            </span>
          </div>
        </div>
      </ng-template>

      <ng-template #notFoundRef ng-notfound-tmp let-searchTerm="searchTerm">
        <div class="ng-option ng-option--not-found">
          <div>
            @switch (searchTerm?.length >= startSearchingAtLength) {
              @case (true) {
                <span>{{
                  '_typeahead.nothing-found%term' | i18next: { term: searchTerm, interpolation: { escapeValue: false } }
                }}</span>
              }
              @default {
                <span>{{ '_typeahead.type-to-search-text%length' | i18next: { length: startSearchingAtLength } }}</span>
              }
            }
          </div>
        </div>
      </ng-template>

      <ng-template #noResultRef ng-notfound-tmp>
        <nxh-no-result-option></nxh-no-result-option>
      </ng-template>

      <ng-template #loadingRef ng-loadingtext-tmp>
        <nxh-loading-option></nxh-loading-option>
      </ng-template>

      <ng-template #checkboxesRef ng-option-tmp let-item$="item$" let-search="searchTerm">
        <nxh-checkbox-option [label]="item$.label" [checked]="item$.selected" [search]="search"> </nxh-checkbox-option>
      </ng-template>

      <ng-template #highlightSearchRef ng-option-tmp let-item$="item$" let-search="searchTerm">
        <span [ngOptionHighlight]="search">{{ item$.label }}</span>
      </ng-template>

      <ng-template ng-label-tmp #labelRef let-item="item" let-clear="clear" let-label="label">
        <span class="ng-value-label">{{ label }}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </ng-template>
    </div>
  `,
  styles: [
    `
      :host {
        display: none;
        max-width: 0;
      }
    `,
  ],
  standalone: true,
  imports: [
    NgOptionHighlightDirective,
    NgSelectModule,
    LoadingOptionComponent,
    SharedUtilI18nModule,
    CheckboxOptionComponent,
    NoResultOptionComponent,
  ],
})
export class NgSelectTemplatesComponent {
  @ViewChild('typeToSearchRef', { static: true }) public typeToSearchRef!: TemplateRef<any>;
  @ViewChild('notFoundRef', { static: true }) public notFoundRef!: TemplateRef<any>;
  @ViewChild('noResultRef', { static: true }) public noResultRef!: TemplateRef<any>;
  @ViewChild('addTagRef', { static: true }) public addTagRef!: TemplateRef<any>;
  @ViewChild('checkboxesRef', { static: true }) public checkboxesRef!: TemplateRef<any>;
  @ViewChild('highlightSearchRef', { static: true }) public highlightSearchRef!: TemplateRef<any>;
  @ViewChild('labelRef', { static: true }) public labelRef!: TemplateRef<any>;
  @ViewChild('loadingRef', { static: true }) public loadingRef!: TemplateRef<any>;

  @Input() startSearchingAtLength = 2;
}
