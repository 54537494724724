<div class="form-group" [formGroup]="parent">
  @if (label) {
    <label class="required" [class.disabled]="parent.disabled" [for]="'time-periods-' + id">{{ label }}</label>
  }
  @for (period of periods.controls; track period; let i = $index; let last = $last) {
    <div formArrayName="timings" class="period__row">
      @if (showEndDate()) {
        <nxh-time-period [parent]="$any(period)" />
      }
      @if (!showEndDate()) {
        <nxh-time-clock [parent]="$any(period)" />
      }
      <nxh-add-remove-buttons
        [showAdd]="last"
        (add)="addPeriod()"
        (remove)="removePeriod(i)"
        [addIconToolTip]="addIconTooltip"
        [removeIconToolTip]="removeIconTooltip"
      />
    </div>
  }
  <val-errors controlName="timings">
    <ng-template valError="minlength" let-error="error"
      >{{ '_errors.minlength' | i18next: { error: error } }}
    </ng-template>
  </val-errors>
</div>
