<button
  nxh-button
  buttonType="subtle-button"
  buttonStatus="neutral"
  tabindex="-1"
  [disabled]="disabled || (submitting$ | async)"
>
  <span #ref><ng-content /></span>
  @if (!ref.hasChildNodes()) {
    {{ label | i18next }}
  }
</button>
