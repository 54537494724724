<div ngbDropdown #dropdown="ngbDropdown" class="hide-default-down-icon" [placement]="placement">
  <div class="d-flex align-items-center confidentiality-select-gap">
    @if (selectedItem) {
      <fa-stack>
        <fa-icon [icon]="['fas', 'circle']" stackItemSize="2x" class="background" />
        <fa-icon [icon]="selectedItem.icon" stackItemSize="1x" class="foreground" />
      </fa-stack>
    } @else {
      <fa-stack>
        <fa-icon icon="circle" stackItemSize="2x" class="background" />
        <fa-icon icon="question" stackItemSize="1x" class="foreground" />
      </fa-stack>
    }
    <button
      type="button"
      [attr.tabindex]="tabindex"
      nxh-button
      buttonType="subtle-button-small"
      ngbDropdownToggle
      #toggleButton
      [disabled]="isDisabled"
      nxhDataTest="confidentiality-btn"
    >
      @if (selectedItem) {
        <span class="selected-item_label">
          {{ selectedItem.label | i18next }}
        </span>
      } @else {
        <span>{{ 'confidentiality' | i18next | titlecase }}</span>
      }
      @if (!isDisabled) {
        @if (dropdown.isOpen()) {
          <fa-icon icon="chevron-up" />
        } @else {
          <fa-icon icon="chevron-down" />
        }
      }
    </button>
  </div>
  <div ngbDropdownMenu>
    @for (item of items; track item; let index = $index) {
      <button
        type="button"
        class="btn dropdown-item"
        (click)="selectItem(item)"
        ngbDropdownItem
        [nxhDataTest]="'confidentiality-item-' + index"
      >
        <fa-icon [icon]="item.icon" />
        <span>{{ item.label | i18next }}</span>
      </button>
    }
  </div>
</div>
