import { Component, Inject } from '@angular/core';
import { POPOVER_DATA } from '@nexuzhealth/shared-ui-toolkit/popover';
import { BubbleConfig } from '@nexuzhealth/shared-ui-toolkit/detail';
import { KeyBoxType } from '@nexuzhealth/shared-key-box-domain';
import { AgendaPlannerTaskViewModel } from '../../shared/models/date.model';

@Component({
  selector: 'nxh-day-planner-bubble',
  templateUrl: './day-planner-bubble.component.html',
  styleUrls: ['./day-planner-bubble.component.scss'],
})
export class DayPlannerBubbleComponent {
  trip: AgendaPlannerTaskViewModel;

  constructor(@Inject(POPOVER_DATA) private bubbleConfig: BubbleConfig) {
    this.trip = this.bubbleConfig.data;
  }

  get privateKeyBox() {
    return this.trip?.data?.careAddress?.addressInfo?.patientAddressKeyBoxes?.find(
      (patientAddressKeyBox) => patientAddressKeyBox.keyBox.type !== KeyBoxType.SHARED,
    );
  }
}
