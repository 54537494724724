<div class="alert__container {{ 'alert__container--' + alertClass }}">
  @if (alertIcon) {
    <div class="alert__icon">
      <fa-icon [icon]="alertIcon" />
    </div>
  }

  <div class="alert__content">
    @if (alertItemVisible) {
      <nxh-alert-item
        [alertTitle]="alertTitle"
        [closeable]="closeable"
        [description]="description"
        (closeAlert)="closeAlert.emit()"
      >
        <ng-content select="nxh-alert-title" ngProjectAs="nxh-alert-title" />
        <ng-content select="nxh-alert-description" ngProjectAs="nxh-alert-description" />
      </nxh-alert-item>
    }

    <ng-content select="nxh-alert-item" />
  </div>
</div>
