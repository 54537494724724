<full-calendar class="full-calendar" [options]="options" />

<ng-template #eventTemplate let-event>
  <div class="calendar-event">
    <div class="calendar-event__header">
      <div class="calendar-event__header--time">
        {{ event.extendedProps['start'] | nxhDate: 'shortTime' }}
      </div>
      @if (event.extendedProps['duration']; as duration) {
        <div class="calendar-event__header--duration">
          {{ duration }}
        </div>
      }
    </div>
    @if (event.extendedProps['title']; as title) {
      <div class="calendar-event__title">
        {{ title }}
      </div>
    }
    <div class="calendar-event__badge-icons">
      @if (event.extendedProps['badge']; as badge) {
        <nxh-pill>{{ badge }}</nxh-pill>
      }
      <div class="calendar-event__badge-icons__icons">
        @for (icon of event.extendedProps['icons'] || []; track icon) {
          <fa-icon [icon]="icon" />
        }
      </div>
    </div>
    @if (event.extendedProps['content']; as content) {
      <span class="calendar-event__content">
        {{ content }}
      </span>
    }
    <div
      class="calendar-event__fade"
      [ngStyle]="{ background: event | eventBackgroundFade: options.eventBackgroundColor }"
    ></div>
  </div>
</ng-template>
<ng-template #dayHeaderTemplate let-header>
  <div class="calendar-header" [class.calendar-header--today]="header.isToday">
    <div class="calendar-header__day-of-month">{{ header.date | nxhDate: 'd' }}</div>
    <div class="calendar-header__day-of-week">{{ header.date | nxhDate: 'EEEE' }}</div>
  </div>
</ng-template>
