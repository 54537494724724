import { NgModule } from '@angular/core';
import { DetailBubbleDirective } from './detail-bubble.directive';
import { DetailBubbleComponent } from './detail-bubble/detail-bubble.component';

@NgModule({
  imports: [
    DetailBubbleComponent,
    DetailBubbleDirective,
  ],
  exports: [DetailBubbleComponent, DetailBubbleDirective],
})
export class DetailModule {}
