<div [class.disabled]="disabled$$ | async" class="group" [class.group--row]="direction === 'row'">
  @if (label) {
    <label
      [class.required]="required"
      [class.disabled]="disabled$$ | async"
      [for]="'group-' + id"
      class="group__label"
      >{{ label }}</label
    >
  }
  <div class="group__control-group">
    <div class="group__control-group__controls" [class.group__control-group__controls--no-gap]="noGap">
      <ng-content />
    </div>
    <div class="group__control-group__errors">
      <ng-content select="val-errors" />
      @if (errorsComponent.length === 0) {
        <val-errors [control]="formGroup" />
      }
    </div>
  </div>
</div>
