import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IconProp } from '@fortawesome/angular-fontawesome/types';
import { POPOVER_ACTIONS, POPOVER_DATA, PopoverRef } from '@nexuzhealth/shared-ui-toolkit/popover';
import { ConfirmData } from './confirm.model';

@Component({
  selector: 'nxh-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmComponent implements OnInit, AfterViewInit {
  confirmText!: string;
  confirmBody: string | undefined;
  showBusyIndicator = false;
  type?: string;
  buttonText!: string;
  icon!: IconProp;

  @ViewChild('btn', { static: true }) btn!: ElementRef;

  busy = false;

  constructor(
    private confirmRef: PopoverRef,
    @Inject(POPOVER_DATA) public data: ConfirmData,
    @Inject(POPOVER_ACTIONS) public actions: { confirm: (confirm: boolean) => void },
  ) {}

  ngOnInit() {
    this.confirmText = this.data.confirmText;
    this.showBusyIndicator = this.data.showBusyIndicator;
    this.type = this.data.confirmType || this.data.type;
    this.buttonText = this.data.buttonText;
    this.icon = this.data.icon;

    if (this.data.confirmBody) {
      this.confirmBody = this.data.confirmBody;
    }
  }

  ngAfterViewInit(): void {
    this.btn.nativeElement.focus();
  }

  cancel() {
    this.confirmRef.close(false);
  }

  confirm() {
    this.actions.confirm(true);
    this.busy = this.showBusyIndicator;
  }

  @HostListener('keydown', ['$event'])
  catchKeyboardEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Escape':
        this.cancel();
    }
  }
}
