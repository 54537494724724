import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/angular-fontawesome/types';
import { of } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AsyncPipe, NgStyle } from '@angular/common';

@Component({
  selector: 'nxh-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FaIconComponent, NgStyle, AsyncPipe],
})
export class ToggleSwitchComponent {
  @Input() iconLeft!: IconProp;
  @Input() iconRight!: IconProp;
  @Input() defaultChecked = false;
  @Output() checked = new EventEmitter<boolean>();
  public transitionDuration$ = of('0.3s').pipe(delay(300), take(1));

  toggle(isChecked: boolean) {
    this.checked.emit(isChecked);
  }
}
