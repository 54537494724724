<!--ng-option--not-found for backwards compatibility, check _selects.scss for more details-->
<div class="ng-option ng-option--not-found">
  <div class="no-result-content">
    @if (error) {
      <div class="error-container">
        <fa-icon [icon]="'times-circle'" />
        <span class="error-description">{{ errorText ? errorText : ('_ng-select.loading-error' | i18next) }}</span>
        <a class="error-cta" href="" (click)="onReload($event)">{{ 'try-again' | i18next }}</a>
      </div>
    } @else {
      @if (notFoundText) {
        <span class="not-found-text">
          {{ notFoundText | i18next }}
        </span>
      }
    }
  </div>
</div>
