import { InjectionToken } from '@angular/core';

export const TABLE_CONFIG = new InjectionToken<TableConfig>('TABLE-CONFIG');

export interface TableConfig {
  debounceLoading: boolean;
}

export function provideTableConfig(tableConfig: TableConfig) {
  return { provide: TABLE_CONFIG, useValue: tableConfig };
}
