<div class="paging-container" [ngClass]="{ tiny: isTiny }">
  @if (!isTiny) {
    <div class="flex-grow-1">
      <ng-content select="[pagingLeftContainer]" />
    </div>
  }

  <div class="paging-show__container" [class.no-label]="minimized">
    <div class="paging-show">{{ '_pager.show' | i18next }}</div>
    <input
      #pageSize
      class="form-control form-control-sm"
      (focus)="pageSize.select()"
      type="number"
      min="1"
      [formControl]="pageSizeControl"
    />
  </div>

  @if (!minimized) {
    <span class="paging-label">{{ label$ | async }}</span>
  }

  <div class="paging__buttons">
    <button nxh-button buttonType="icon" [outline]="true" [disabled]="prevDisabled$ | async" (click)="prevClicked()">
      <fa-icon icon="angle-left" />
    </button>
    <button nxh-button buttonType="icon" [outline]="true" [disabled]="nextDisabled$ | async" (click)="nextClicked()">
      <fa-icon icon="angle-right" />
    </button>
  </div>
</div>
