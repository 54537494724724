@if (longPlaying) {
  <div class="loading-spinner">
    <fa-icon class="loading-spinner__icon animate-hourglass" animation="beat" icon="hourglass-start" />
    <span class="loading-spinner__content">{{ 'loading-search-results--long-playing' | i18next }}</span>
  </div>
} @else {
  <div class="loading-spinner">
    <fa-icon class="loading-spinner__icon" animation="spin" icon="sync" />
    <span class="loading-spinner__content">{{ 'loading-search-results' | i18next }}</span>
  </div>
}
