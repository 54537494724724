<div class="icon-pill-content-container">
  <ng-content select="fa-icon" />

  <span class="pill-content">
    <ng-content />
  </span>
</div>

<div class="pill-buttons">
  <ng-content select="button" />
  @if (editable) {
    <button
      #edit
      nxh-button
      [buttonType]="'icon'"
      [ngbTooltip]="editTooltip"
      [disableTooltip]="!editTooltip"
      (click)="editClick.emit()"
    >
      <fa-icon icon="pen" />
    </button>
  }
  @if (closeable) {
    <button
      #close
      nxh-button
      [buttonType]="'icon'"
      [ngbTooltip]="closeTooltip"
      [disableTooltip]="!closeTooltip"
      (click)="closeClick.emit()"
    >
      <fa-icon icon="times" />
    </button>
  }
</div>
