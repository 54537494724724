@if (!!parent) {
  <div class="nh-row row__periodicity" [formGroup]="parent">
    <div class="nh-col">
      <div class="no-capitalization">
        {{ '_resource-planning._recurrence._periodicity-' + type + '.descr-1' | i18next }}
      </div>
      <nxh-number-field [min]="1" [roundingPrecision]="0" formControlName="period" />
      <div class="no-capitalization">
        {{ '_resource-planning._recurrence._periodicity-' + type + '.descr-2' | i18next }}
        @if (type === 'weekly') {
          <span class="required"></span>
        }
      </div>
    </div>
  </div>
  <div class="nh-row row__periodicity--error">
    <val-errors
      class="nh-col"
      [control]="parent.get('period')"
      [label]="'_resource-planning._recurrence._periodicity-' + type + '.label' | i18next"
    />
  </div>
}
