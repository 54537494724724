import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { I18NextModule } from 'angular-i18next';
import { NgSelectErrorDirective } from '../../ng-select-error.directive';

@Component({
  selector: 'nxh-no-result-option',
  templateUrl: 'no-result-option.component.html',
  styleUrls: ['no-result-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconsModule, I18NextModule],
})
export class NoResultOptionComponent {
  readonly #ngSelect? = inject(NgSelectComponent, { optional: true });
  readonly #ngSelectErrorDirective? = inject(NgSelectErrorDirective, { optional: true });

  get notFoundText() {
    return this.#ngSelect?.notFoundText;
  }

  get error() {
    return !!this.#ngSelectErrorDirective?.error;
  }

  get errorText() {
    return this.#ngSelectErrorDirective?.errorText;
  }

  onReload($event: MouseEvent) {
    $event.preventDefault();
    const hasReloadSubscriptions =
      this.#ngSelectErrorDirective && this.#ngSelectErrorDirective?.reload.observers.length > 0;
    if (hasReloadSubscriptions) {
      this.#ngSelectErrorDirective?.reload.emit();
      return;
    }
    window.location.reload();
  }
}
