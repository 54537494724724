<div class="back-button-container">
  @switch (mode) {
    @case ('default') {
      <a (click)="$event.stopPropagation(); $event.preventDefault(); goBack()" nxhDataTest="back-button">
        <ng-container *ngTemplateOutlet="backIcon" />
      </a>
    }
    @case ('fixed') {
      <ng-container *ngTemplateOutlet="backIcon" />
    }
    @case ('custom') {
      <a (click)="$event.stopPropagation(); $event.preventDefault(); click.emit()" nxhDataTest="back-button">
        <ng-container *ngTemplateOutlet="backIcon" />
      </a>
    }
  }
  <div class="ng-content-container">
    <ng-content />
  </div>
</div>

<ng-template #backIcon>
  <span [ngbTooltip]="tooltip | i18next" nxhDataTest="back-icon">
    <fa-icon icon="long-arrow-left" class="long-arrow-left" />
  </span>
</ng-template>
