import { PageLoaderStatus } from '@nexuzhealth/shared-ui-toolkit/loading-states/page-loader';
import { isLoadingStateDetails, LoadingStateDetails } from '@nexuzhealth/shared-util';

export type LoadingStatus = 'loading' | 'error' | 'empty' | 'loaded';

export function toLoadingStatus(
  value: PageLoaderStatus | LoadingStatus | LoadingStateDetails<any> | null,
): LoadingStatus | null {
  if (value) {
    if (isLoadingStateDetails(value)) {
      return getLoadingStatus(value);
    } else {
      return value as LoadingStatus;
    }
  }
  return null;
}

function getLoadingStatus<R>(state: LoadingStateDetails<R> | null): LoadingStatus {
  if (!state || state.loading) {
    return 'loading';
  }
  if (state.empty) {
    return 'empty';
  }
  if (state.error) {
    return 'error';
  }
  return 'loaded';
}
