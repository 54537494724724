<div
  class="form-group"
  [class.disabled]="disabled$ | async"
  [class.form-group--no-bottom-margin]="noBottomMargin"
  [class.form-group--row]="direction === 'row'"
>
  @if (!contentFirst) {
    @if ((label || reserveSpaceForLabel) && !noLabel) {
      <label
        class="form-label"
        [class.form-label--row]="direction === 'row'"
        [for]="id"
        [class.required]="required"
        [class.disabled]="disabled$ | async"
        >{{ label }}</label
      >
    }
    @if (tooltip) {
      <fa-icon [icon]="tooltipIcon" [ngbTooltip]="tooltip" class="form-label" />
    }
  }
  <div class="ng-content-container">
    <ng-content />
  </div>
  @if (contentFirst) {
    @if (label && !noLabel) {
      <label class="form-label" [for]="id" [class.required]="required" [class.disabled]="disabled$ | async">{{
        label
      }}</label>
    }
    @if (tooltip) {
      <fa-icon [icon]="tooltipIcon" [ngbTooltip]="tooltip" class="form-label" />
    }
  }
</div>
