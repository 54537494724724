import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Pipe({
  name: 'rangeBoundaryWontChange',
  standalone: true,
})
export class RangeBoundaryWontChangePipe implements PipeTransform {
  transform(
    date: NgbDate,
    fromDate: NgbDate | null,
    toDate: NgbDate | null,
    focussedInput: 'from' | 'to' | null,
  ): boolean {
    if (date.equals(fromDate) && date.equals(toDate)) {
      return false;
    }

    return (date.equals(fromDate) && focussedInput === 'to') || (date.equals(toDate) && focussedInput === 'from');
  }
}
