import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { faHandshake, faUserLock } from '@fortawesome/pro-regular-svg-icons';
import { Focusable } from '@nexuzhealth/shared-ui-toolkit/focus';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { Confidentiality } from '@nexuzhealth/shared-domain';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { I18NextModule } from 'angular-i18next';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { TitleCasePipe } from '@angular/common';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';

const confidentiality_select_items = [
  {
    value: Confidentiality.therapeutic_relation,
    label: '_enum.therapeutic_relation',
    icon: faHandshake,
  },
  { value: Confidentiality.author, label: '_enum.author', icon: faUserLock },
];

type ConfidentialitySelectItem = (typeof confidentiality_select_items)[number];
type ConfidentialitySelectItemKey = Confidentiality.therapeutic_relation | Confidentiality.author;

@Component({
  selector: 'nxh-confidentiality-select',
  templateUrl: './confidentiality-select.component.html',
  styleUrls: ['./confidentiality-select.component.scss'],
  standalone: true,
  imports: [
    IconsModule,
    ReactiveFormsModule,
    I18NextModule,
    NgbDropdownModule,
    SharedTechFeatureE2eModule,
    TitleCasePipe,
    ButtonModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ConfidentialitySelectComponent),
    },
  ],
})
export class ConfidentialitySelectComponent implements Focusable, ControlValueAccessor {
  @ViewChild('toggleButton', { static: true }) button!: ElementRef<HTMLButtonElement>;
  @Input() tabindex = -1;
  @Input() placement: PlacementArray = 'top';

  items: ConfidentialitySelectItem[] = confidentiality_select_items;
  selectedItem?: ConfidentialitySelectItem;
  isDisabled = false;

  private onChange!: (confidentiality: Confidentiality | null) => void;
  private onTouch!: () => void;

  registerOnChange(fn: (confidentiality: Confidentiality | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: ConfidentialitySelectItemKey): void {
    this.selectedItem = this.items.find((conf) => conf.value === value);
  }

  selectItem(item: ConfidentialitySelectItem) {
    if (this.selectedItem !== item) {
      this.selectedItem = item;
      this.onChange(item.value);
    }
    this.onTouch();
  }

  setFocus() {
    this.button.nativeElement.focus();
  }
}
