import { Component, ContentChild, forwardRef, HostBinding, inject, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DataTestDirective, SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { NgTemplateOutlet } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { ToggleListOption, ToggleListOptionDirective } from '../toggle-list-option.directive';
import { ToggleGroupDirective } from '../toggle-group.directive';
import { ToggleListOptionComponent } from '../toggle-list-option/toggle-list-option.component';

@Component({
  selector: 'nxh-single-toggle-list',
  templateUrl: './single-toggle-list.component.html',
  styleUrls: ['./single-toggle-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleToggleListComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SharedTechFeatureE2eModule,
    NgTemplateOutlet,
    I18NextModule,
    ToggleGroupDirective,
    ToggleListOptionComponent,
  ],
})
export class SingleToggleListComponent<T> implements ControlValueAccessor, OnInit {
  /**
   * The to-be-displayed options
   */
  @Input() options!: ToggleListOption<T>[];

  /**
   * Defines the look-and-feel, i.e. either as 'pills' or 'segmented controller'
   */
  @Input() pills = false;

  /**
   * Specifies whether there is padding (not compact) or not (compact). By default the component is compact, unless:
   * - the options are laid out in a grid
   * - a description is shown via the bindDescription input
   */
  @Input() compact: boolean | undefined = undefined;

  /**
   * Lays out the options in a grid. When true a layout of 3 columns is assumed. Pass a number from 1 to 4 to
   * specify the exact number of columns.
   */
  @Input() grid: boolean | 1 | 2 | 3 | 4 = false;

  /**
   * Specifies the option's 'label' field
   */
  @Input() bindLabel?: keyof ToggleListOption<T>;

  /**
   * Specifies the option's 'description' field
   */
  @Input() bindDescription?: keyof ToggleListOption<T>;

  /**
   * Use this to hide the label.
   */
  @Input() noLabel = false;

  /**
   * Shows a radio button before the label/description
   */
  @Input() showRadio = false;

  @ContentChild(ToggleListOptionDirective) optionDirective!: ToggleListOptionDirective;

  formControl = new FormControl<string | null>(null);
  testPrefix = 'single-toggle-list';
  protected randomId = Math.random().toString();
  private dataTestDirective = inject(DataTestDirective, { optional: true });

  @HostBinding('class.toggle-list-component--compact') get isCompact() {
    const large = (!!this.grid || !!this.bindDescription) && this.compact !== true;
    return !large;
  }

  get gridClasses() {
    if (this.grid) {
      if (this.grid === true) {
        return 'btn-group-toggle--grid';
      } else {
        return `btn-group-toggle--grid btn-group-toggle--grid-${this.grid}`;
      }
    } else {
      return '';
    }
  }

  ngOnInit(): void {
    if (this.dataTestDirective) {
      this.testPrefix = this.dataTestDirective.nxhDataTest;
    }
  }

  registerOnChange(fn: any): void {
    this.formControl.valueChanges.subscribe((value) => fn(value));
  }

  registerOnTouched(fn: any): void {
    this.formControl.valueChanges.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable({ emitEvent: false });
    } else {
      this.formControl.enable({ emitEvent: false });
    }
  }

  writeValue(value: string): void {
    this.formControl.setValue(value, { emitEvent: false });
  }
}
