import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { I18NextModule } from 'angular-i18next';
import { AsyncPipe } from '@angular/common';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { BehaviorSubject, combineLatest, map, Observable, of } from 'rxjs';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { RouterLink } from '@angular/router';
import { take } from 'rxjs/operators';
import { FilterOnService } from './filter-on.service';

@Component({
  selector: 'nxh-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [I18NextModule, IconsModule, ButtonModule, AsyncPipe, SharedTechFeatureE2eModule, RouterLink],
})
export class EmptyComponent {
  /**
   * Custom "empty state" label
   */
  @Input() label?: string;

  /**
   * Custom "empty state" description
   */
  @Input() description?: string;

  /**
   * Custom label for the empty search state
   */
  @Input() noItemsFoundLabel?: string | null;

  /**
   * Specifies whether the data is empty as a result of a filter
   */
  @Input({ transform: booleanAttribute }) set filterOn(filterOn: boolean) {
    this.filterOn$$.next(filterOn);
  }

  /**
   * Custom label for when there is a call-to-action accompanying the empty state message
   */
  @Input() createItemLabel?: string;

  /**
   * Convenience way to disable showing the createItem button or link
   */
  @Input() disableCreateItem: boolean | null = null;

  /**
   * RouterLink for when there is a call-to-action _link_ accompanying the empty state message
   */
  @Input() createItemLink?: RouterLink['routerLink'];

  /**
   * Defines the various sizes and spacings of the various components. Default is 'md', except when the component
   * is used inside a PageBodyComponent.
   */
  @Input() size: 'md' | 'lg' = 'md';

  /**
   * Cf. RouterLink['relativeTo']. Use in combination with createItemLink
   */
  @Input() createItemLinkRelativeTo?: RouterLink['relativeTo'];

  /**
   * For when the user has clicked on the call-to-action button
   */
  @Output() createItem = new EventEmitter<void>();

  private filterOn$$ = new BehaviorSubject<boolean | null>(null);
  private parentFilterOn$: Observable<boolean | null> =
    inject(FilterOnService, { optional: true })?.filterOn$ ?? of(null);

  isFilterOn$ = combineLatest([
    this.parentFilterOn$.pipe(
      // this component will always be recreated when the filter state from the parent changes, because there will always be
      // an intermediate loading step
      take(1),
    ),
    this.filterOn$$,
  ]).pipe(
    map(([parentFilterOn, localFilterOn]) => {
      if (localFilterOn !== null) {
        return localFilterOn;
      } else if (parentFilterOn !== null) {
        return parentFilterOn;
      } else {
        return false;
      }
    }),
  );
}
