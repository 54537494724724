@for (timing of timings; track timing; let i = $index; let last = $last) {
  <div [formGroup]="timing" class="timing" [nxhDataTest]="'timing-' + i">
    <div class="timing__date">
      <span class="timing__date--label">{{ '_resource-planning._recurrence._manual.on' | i18next }}</span>
      <nh-control class="timing__date--picker">
        <nxh-date-picker formControlName="date" />
        <val-errors [label]="'date' | i18next">
          <ng-template valError="dateNotInPeriods">{{
            '_resource-planning._recurrence._manual.dateNotInPeriods' | i18next
          }}</ng-template>
        </val-errors>
      </nh-control>
    </div>
    <div class="timing__timings">
      @if (useTimeslots && showEndDate()) {
        <nxh-time-period [parent]="timing" />
      }
      @if (useTimeslots && !showEndDate()) {
        <nxh-time-clock [parent]="timing" />
      }
      @if (timing.get('dayparts'); as daypartsFormGroup) {
        <nxh-dayparts
          [parent]="$any(daypartsFormGroup)"
          [partOptions]="dayparts"
          [useDayPartAllDay]="useDayPartAllDay"
          [validateDayPartOrTime]="validateDayPartOrTime"
        />
      }
      <val-errors class="val-errors-timing" [control]="parent">
        <ng-template valError="desired-times-must-match">
          {{ '_resource-planning._recurrence.error-desired-times-must-match' | i18next }}
        </ng-template>
      </val-errors>
      <val-errors class="val-errors-timing" [control]="parent">
        <ng-template valError="selection-alldayortime-must-match">
          {{ '_resource-planning._recurrence.error-selection-alldayortime-must-match' | i18next }}
        </ng-template>
      </val-errors>
    </div>
    <nxh-add-remove-buttons
      [showAdd]="last"
      (add)="addTiming()"
      (remove)="removeTiming(i)"
      [addIconToolTip]="'_resource-planning._recurrence._manual.add' | i18next"
      [removeIconToolTip]="'_resource-planning._recurrence.remove' | i18next"
      class="timing__add-remove-buttons"
    />
  </div>
}
