@if (hasPrefix$ | async) {
  <div class="nxh-input__prefix-container">
    <ng-content select="[nxhPrefix]" />
  </div>
}
<input
  #nativeInput
  [type]="type"
  class="form-control"
  [placeholder]="placeholder"
  [tabIndex]="tabIndex"
  (input)="valueChange($event)"
  (blur)="onTouch($event)"
  [value]="value"
/>
@if (hasSuffix$ | async) {
  <div class="nxh-input__suffix-container">
    <ng-content select="[nxhSuffix]" />
  </div>
}
