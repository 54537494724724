import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NavItem } from '@nexuzhealth/shared-domain';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { SharedSettingsFeatureFlagsUiModule } from '@nexuzhealth/shared-settings-feature-flags-ui';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { I18NextModule } from 'angular-i18next';

@Component({
  selector: 'nxh-tabnav',
  templateUrl: './tabnav.component.html',
  styleUrls: ['./tabnav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedSettingsFeatureFlagsUiModule,
    NgIf,
    RouterLink,
    RouterLinkActive,
    NgClass,
    I18NextModule,
    AsyncPipe,
    NgForOf,
  ],
  standalone: true,
})
export class TabnavComponent implements OnInit {
  readonly #router = inject(Router);

  @ViewChildren('rla') routerLinks!: QueryList<RouterLinkActive>;
  @Input() navItems!: NavItem[];
  @Input() hideForRouteRegexes: RegExp[] = [];
  @Input() skipLocationChange = false;
  @HostBinding('class.top-level') @Input() topLevel = false;
  /**
   * Specifies active tab. Typically we would let the RouterLinkActive directive take care of this. But
   * there seems to be some issues with this when dealing with secondary nested routing.
   */
  @Input() activeItem: number | null = null;
  @Output() btnActionEmitter = new EventEmitter<void>();

  hide$!: Observable<boolean>;

  get routerLinkActiveOptions(): RouterLinkActive['routerLinkActiveOptions'] {
    return { exact: false };
  }

  ngOnInit(): void {
    this.hide$ = this.#router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e) => (<NavigationEnd>e).urlAfterRedirects),
      startWith(this.#router.url),
      map((url) => this.hideForRouteRegexes.some((regex) => regex.test(url))),
    );
  }
}
