import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

export enum Environment {
  LOCAL = 'local',
  DEV = 'dev',
  TST = 'tst',
  ACC = 'acc',
  PRD = 'prd',
}

export enum EnvironmentFull {
  LOCAL = 'local',
  DEV = 'development',
  TST = 'test',
  ACC = 'acceptance',
  PRD = 'production',
}

const environmentFullLookup = {
  [Environment.LOCAL]: EnvironmentFull.LOCAL,
  [Environment.DEV]: EnvironmentFull.DEV,
  [Environment.TST]: EnvironmentFull.TST,
  [Environment.ACC]: EnvironmentFull.ACC,
  [Environment.PRD]: EnvironmentFull.PRD,
} as const;

@Injectable({ providedIn: 'root' })
export class EnvironmentsService {
  readonly hostname = inject(DOCUMENT).location.hostname;
  readonly environment = getEnvironment(this.hostname);
  readonly environmentFull = environmentFullLookup[this.environment];
  readonly substage = getSubstage(this.hostname);
}

export function getEnvironment(hostname: string, separator = '.'): Environment {
  const hostParts = hostname.split(separator);

  return Object.values(Environment).find((hostPart) => hostParts[1] === hostPart) || Environment.PRD;
}

export function getSubstage(hostname: string, separator = '.'): string {
  const hostParts = hostname.split(separator);

  const substageDashIndex = hostParts[0].indexOf('-');
  if (substageDashIndex !== -1) {
    return hostParts[0].substring(substageDashIndex);
  }

  return ''; //no substage, nothing to return
}
