import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { NgSelectComponent } from '@ng-select/ng-select';
import { I18NextModule } from 'angular-i18next';

@Component({
  selector: 'nxh-loading-option',
  templateUrl: 'loading-option.component.html',
  styleUrls: ['loading-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconsModule, I18NextModule],
})
export class LoadingOptionComponent {
  readonly #ngSelect = inject(NgSelectComponent, { optional: true });

  get loadingText() {
    return this.#ngSelect?.loadingText;
  }
}
