<h4 class="modal-title">
  @if (title) {
    {{ title }}
  }
  <ng-content />
</h4>
<div class="modal-header-controls">
  <ng-content select="nxh-modal-controls" />
  <button
    nxh-button
    buttonType="subtle-button"
    buttonStatus="neutral"
    (click)="dismiss.next()"
    nxhDataTest="nxh-card-modal-header-close-button"
    tabindex="-1"
  >
    <fa-icon icon="times" size="2x" />
  </button>
</div>
