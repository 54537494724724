<nxh-stack alignItems="center" gap="lg" nowrap="true" class="radio-option-container">
  @if (showRadio) {
    <div class="radio-button">
      @switch (active) {
        @case (true) {
          <fa-icon [icon]="['fas', 'circle-dot']" [fixedWidth]="true" class="active" />
        }
        @default {
          <fa-layers [fixedWidth]="true">
            <!-- Not stacking 2 solids because we want to retain the background! -->
            <fa-icon [icon]="'circle'" />
            <fa-icon [icon]="'circle'" transform="shrink-2" />
            <fa-icon [icon]="'circle'" transform="shrink-3" />
          </fa-layers>
        }
      }
    </div>
  }

  <nxh-stack direction="col" alignItems="start" class="option-container">
    @if (label) {
      <div class="toggle-list-option-label">{{ label }}</div>
    }
    @if (description || toggleListOptionDescription) {
      <div class="toggle-list-option-description">
        {{ description }}
        <ng-content select="[nxhToggleListOptionDescription]" />
      </div>
    }
    <ng-content />
  </nxh-stack>
</nxh-stack>
