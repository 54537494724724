<nxh-recurrence-period [parent]="parent" type="weekly" />

<ng-container [formGroup]="parent">
  @if (daysOptional) {
    <div class="days-optional-group">
      <nh-radio-group class="day-group" [formControl]="radioSelect">
        <div class="nh-row">
          <nh-radio value="frequency" [label]="'&zwj;'" />
          <div class="inline-radio-form">
            <div class="nh-row">
              <nxh-number-field [formControl]="frequency" [min]="1" [max]="7" [roundingPrecision]="0" />
              <div class="no-capitalization">
                {{ '_frequency.times-per-week' | i18next }}
              </div>
            </div>
            <div>
              <val-errors [control]="frequency" [label]="'frequentie' | i18next" />
            </div>
          </div>
        </div>
        <div class="nh-row">
          <nh-radio
            class="days-radio"
            value="weekday"
            [label]="'_frequency.on-the-following-days' | i18next"
            [nxhDataTest]="'radio-weekday-rec-following-days'"
          />
          <div class="nh-row row__days">
            <div class="nh-col">
              <nxh-days-selector
                [selected]="days?.value | keys"
                [disabled]="days?.disabled"
                (addDay)="onAddDay($event)"
                (removeDay)="onRemoveDay($event)"
              />
              <val-errors [control]="weekFormGroup">
                <ng-template valError="minOneDay">
                  {{ '_resource-planning._recurrence._errors.min-one-day' | i18next }}
                </ng-template>
              </val-errors>
            </div>
          </div>
        </div>
      </nh-radio-group>
    </div>
  }

  @if (!daysOptional) {
    <div class="nh-row row__days">
      <div class="nh-col">
        <nxh-days-selector
          [selected]="days?.value | keys"
          (addDay)="onAddDay($event)"
          (removeDay)="onRemoveDay($event)"
        />
        <val-errors [control]="weekFormGroup">
          <ng-template valError="minOneDay">
            {{ '_resource-planning._recurrence._errors.min-one-day' | i18next }}
          </ng-template>
        </val-errors>
      </div>
    </div>
  }

  @if (useTimeslots) {
    <div>
      @if (!useSameTimingsForAllDays) {
        @for (dayGroup of days?.controls; track dayGroup) {
          <div [formGroup]="$any(dayGroup)" class="nh-row row__day-periods">
            <div class="nh-col-2">
              {{ dayGroup.get('weekday')?.value | i18next }}
            </div>
            <div class="nh-col">
              <nxh-time-periods
                [parent]="$any(dayGroup)"
                [addIconTooltip]="'_resource-planning._recurrence.add' | i18next"
                [removeIconTooltip]="'_resource-planning._recurrence.remove' | i18next"
              />
            </div>
          </div>
        }
      }
      @if (useSameTimingsForAllDays && days?.controls && days.controls.length > 0) {
        <div class="nh-row">
          <nxh-time-periods
            [parent]="weekFormGroup"
            [addIconTooltip]="'_resource-planning._recurrence.add' | i18next"
            [removeIconTooltip]="'_resource-planning._recurrence.remove' | i18next"
          />
        </div>
      }
    </div>
  }

  @if (useDayparts) {
    <div>
      @if (!useSameTimingsForAllDays) {
        <nh-checkbox
          [switch]="true"
          [formControl]="sameTimingsForAllDaysFormControl"
          [label]="'_resource-planning._recurrence.same-dayparts-for-all-days' | i18next"
          [nxhDataTest]="'select-sameTimingsForAllDaysFormControl'"
        />
      }
      @if (weekFormGroup.get('dayparts'); as daypartsFormGroup) {
        <nxh-dayparts
          [parent]="$any(daypartsFormGroup)"
          [partOptions]="dayparts"
          [useDayPartAllDay]="useDayPartAllDay"
          [validateDayPartOrTime]="validateDayPartOrTime"
        />
      }
      @for (dayGroup of days?.controls; track dayGroup; let i = $index) {
        @if (!sameTimingsForAllDaysFormControl.value && dayGroup.get('dayparts'); as daypartsFormGroup) {
          <nxh-dayparts
            [title]="dayGroup.get('weekday')?.value"
            [parent]="$any(daypartsFormGroup)"
            [partOptions]="dayparts"
            [useDayPartAllDay]="useDayPartAllDay"
            [validateDayPartOrTime]="validateDayPartOrTime"
            [nxhDataTest]="'dayparts-' + i"
          />
        }
      }
    </div>
  }
</ng-container>
