import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  NgZone,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbInputDatepicker,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, concat, defer, fromEvent, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { addDataTestAttributes, DataTestDirective } from '@nexuzhealth/shared-tech-feature-e2e';
import { AsyncPipe, DOCUMENT } from '@angular/common';
import { guid } from '@datorama/akita';
import { DateRangeValidators, endDateAfterStartDateValidator, SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Range } from '@nexuzhealth/shared-domain';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { NxhFormsModule } from '@nexuzhealth/shared-ui-toolkit/forms';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { jsDateToNgbDate, ngbDateToJsDate } from '../ngb-date-utils';
import { CustomDateParserFormatterService } from '../custom-date-parser-formatter.service';
import { DayViewComponent } from '../day-view/day-view.component';
import { RangeBoundaryWontChangePipe } from './range-boundary-wont-change.pipe';

const isContainedIn = (element: HTMLElement, array?: HTMLElement[]) =>
  array ? array.some((item) => item?.contains(element)) : false;

@Component({
  selector: 'nxh-range-picker',
  templateUrl: './range-picker.component.html',
  styleUrls: ['./range-picker.component.scss', '../datepicker-ngb.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    NgbTooltipModule,
    NgbDatepickerModule,
    DayViewComponent,
    IconsModule,
    ButtonModule,
    NxhFormsModule,
    SharedUtilI18nModule,
    RangeBoundaryWontChangePipe
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: RangePickerComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangePickerComponent implements ControlValueAccessor, OnInit, Validator, AfterViewInit {
  @Input() showToday = false;
  @Input() outsideDays: NgbInputDatepicker['outsideDays'] = 'collapsed';
  @Input() showLabels = false;
  @Input() appendTo: 'body' | null = null;
  @Input() minDate: Date | null = null;

  @ViewChild('datepicker', { static: true }) private datepicker!: NgbInputDatepicker;
  @ViewChild('fromInput', { static: true }) private fromInputViewChild!: ElementRef<HTMLInputElement>;
  @ViewChild('toInput', { static: true }) private toInputViewChild!: ElementRef<HTMLInputElement>;

  protected id!: string;
  protected fromDateRequired: boolean | null = null;
  protected toDateRequired!: boolean;
  protected rangeGroup!: FormGroup<{ fromDate: FormControl<string | null>; toDate: FormControl<string | null> }>;
  protected readonly hoverDate$$ = new BehaviorSubject<NgbDate | null>(null);
  protected fromDate$!: Observable<NgbDate | null>;
  protected toDate$!: Observable<NgbDate | null>;

  protected focussedInput$$ = new BehaviorSubject<'from' | 'to' | null>(null);

  private onTouched!: () => void;
  private onChange!: (range: Partial<Range> | null) => void;

  constructor(
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private zone: NgZone,
    private destroyRef: DestroyRef,
    @Inject(DOCUMENT) private document: Document,
    @Optional() private dataTestDirective?: DataTestDirective,
  ) {}

  get allFieldsTouched() {
    return this.rangeGroup.get('fromDate')?.touched && this.rangeGroup.get('toDate')?.touched;
  }

  get toDate(): NgbDate | null {
    return this.rangeGroup.value?.toDate ? NgbDate.from(this.formatter.parse(this.rangeGroup.value.toDate)) : null;
  }

  ngOnInit(): void {
    this.id = 'footer_' + guid();

    this.rangeGroup = new FormGroup(
      {
        fromDate: new FormControl<string | null>(null, this.validateDate({ minDate: true })),
        toDate: new FormControl<string | null>(null, this.validateDate()),
      },
      endDateAfterStartDateValidator('fromDate', 'toDate', true, (date) => {
        if (typeof date === 'string') {
          const parsed = this.formatter.parse(date);
          // for some reason we get a "The call would have succeeded against this implementation, but implementation
          // signatures of overloads are not externally visible." exception when passing parsed to ngbDateToJsDate
          // without first checking for null
          return parsed ? ngbDateToJsDate(parsed) : null;
        } else {
          return null;
        }
      }),
    );

    // defer is necessary when starting from a filled-in range-picker
    this.fromDate$ = defer(() =>
      concat(of(this.rangeGroup.value?.fromDate), this.rangeGroup.controls.fromDate.valueChanges).pipe(
        distinctUntilChanged(),
        map((date) => (date ? NgbDate.from(this.formatter.parse(date)) : null)),
        shareReplay({ bufferSize: 1, refCount: true }),
      ),
    );

    this.toDate$ = defer(() =>
      concat(of(this.rangeGroup.value?.toDate), this.rangeGroup.controls.toDate.valueChanges).pipe(
        distinctUntilChanged(),
        map((date) => (date ? NgbDate.from(this.formatter.parse(date)) : null)),
        shareReplay({ bufferSize: 1, refCount: true }),
      ),
    );

    this.rangeGroup.valueChanges
      .pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((range) => {
        if (this.onChange) {
          const jsRange = this.mapFormValueToJsRange(range);
          this.onChange(jsRange);
        }
      });
  }

  onDateSelection(date: NgbDate) {
    const currentFromDate = this.rangeGroup.controls.fromDate?.value
      ? this.formatter.parse(this.rangeGroup.controls.fromDate.value)
      : null;
    const currentToDate = this.rangeGroup.controls.toDate?.value
      ? this.formatter.parse(this.rangeGroup.controls.toDate.value)
      : null;

    this.focussedInput$$.pipe(take(1)).subscribe((focussedInput) => {
      let newRange: { fromDate: string | null; toDate: string | null };
      let toFocus: HTMLInputElement;
      const formattedDate = this.formatter.format(date);
      if (focussedInput === 'from') {
        toFocus = this.toInputViewChild.nativeElement;
        newRange = {
          fromDate: formattedDate,
          toDate:
            currentToDate !== null && date.after(currentToDate) ? formattedDate : this.rangeGroup.controls.toDate.value,
        };
      } else {
        toFocus = this.fromInputViewChild.nativeElement;
        newRange = {
          toDate: formattedDate,
          fromDate:
            currentFromDate !== null && date.before(currentFromDate)
              ? formattedDate
              : this.rangeGroup.controls.fromDate.value,
        };
      }

      setTimeout(() => {
        this.rangeGroup.setValue(newRange);
        toFocus.focus();
      });
    });
  }

  onMouseEnter(date: NgbDate) {
    this.hoverDate$$.next(date);
  }

  onMouseLeave() {
    this.hoverDate$$.next(null);
  }

  openDatepicker(elementRef: HTMLInputElement, formControlName: 'fromDate' | 'toDate') {
    if (!this.datepicker.isOpen()) {
      const wasTouched = this.rangeGroup.get(formControlName)?.touched;
      this.datepicker.open();
      this.setupOutsideClickHandler();
      setTimeout(() => {
        // When opening the datepicker, ngb-bootstrap puts the focus on the datepicker. However we want to retain the focus on
        // the clicked-upon input field. Therefore we put the focus back on that input field, and undo its "touched" status (which
        // it gathered as a result of moving the focus to the datepicker).
        if (!wasTouched) {
          this.rangeGroup.get(formControlName)?.markAsUntouched();
        }
        elementRef.focus();
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.rangeGroup.disable();
    } else {
      this.rangeGroup.enable();
    }
  }

  writeValue(range: Partial<Range>): void {
    if (range) {
      const mappedRange = this.mapRangeToFormValue(range);
      this.rangeGroup.setValue({ fromDate: mappedRange?.fromDate || null, toDate: mappedRange?.toDate || null });
    } else {
      this.rangeGroup.reset();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // A cleaner way to accomplish this would be to inject the NgControl, but this has some nasty side-effects
    // (https://github.com/angular/angular/issues/29218)
    if (this.fromDateRequired === null) {
      this.fromDateRequired =
        control.hasValidator(DateRangeValidators.fromDateRequired) || control.hasValidator(Validators.required);
      this.toDateRequired = control.hasValidator(Validators.required);
    }

    // Let consumer know control is not valid. Return an error for which there does not exist a val-errors mapping
    // so validation error messages are not shown twice.
    if (!this.rangeGroup.valid) {
      return { __cvaInternalError__: true };
    }

    return null;
  }

  ngAfterViewInit(): void {
    if (this.dataTestDirective) {
      addDataTestAttributes(
        this.dataTestDirective.nxhDataTest,
        {
          element: this.fromInputViewChild.nativeElement,
          suffix: '_from-date-input',
        },
        {
          element: this.toInputViewChild.nativeElement,
          suffix: '_to-date-input',
        },
      );
    }
  }

  private setupOutsideClickHandler() {
    // only trigger changedetection when datepicker has to be closed
    this.zone.runOutsideAngular(() => {
      fromEvent(this.document, 'click')
        .pipe(takeUntil(this.datepicker.closed))
        .subscribe((event: Event) => {
          const element = event.target as HTMLElement;
          if (
            // don't close datepicker when we clicked on the datepicker or 1 of the input fields
            !isContainedIn(element, [
              this.document.querySelector(`.${this.id}`) as HTMLElement,
              this.fromInputViewChild.nativeElement,
              this.toInputViewChild.nativeElement,
            ])
          ) {
            this.zone.run(() => {
              this.closeDatepicker();
            });
          }
        });
    });
  }

  private closeDatepicker() {
    if (this.datepicker.isOpen()) {
      this.datepicker.close();
      this.onTouched();
      this.cleanup();
    }
  }

  private cleanup() {
    this.hoverDate$$.next(null);
  }

  private mapRangeToFormValue(range: Partial<Range>): FormValue | null {
    return range
      ? {
          fromDate: range.fromDate ? this.formatter.format(jsDateToNgbDate(range.fromDate)) : null,
          toDate: range.toDate ? this.formatter.format(jsDateToNgbDate(range.toDate)) : null,
        }
      : null;
  }

  private mapFormValueToJsRange(range: FormValue): Partial<Range> | null {
    const fromDate = range.fromDate ? this.formatter.parse(range.fromDate) : null;
    const toDate = range.toDate ? this.formatter.parse(range.toDate) : null;
    return range
      ? {
          // for some reason we get a "The call would have succeeded against this implementation, but implementation
          // signatures of overloads are not externally visible." exception when passing parsed to ngbDateToJsDate
          // without first checking for null
          fromDate: fromDate ? ngbDateToJsDate(fromDate) : undefined,
          toDate: toDate ? ngbDateToJsDate(toDate) : undefined,
        }
      : null;
  }

  private validateDate: (config?: { minDate: boolean }) => ValidatorFn = ({ minDate } = { minDate: false }) => {
    return (control: AbstractControl) => {
      if (control.value) {
        const parsed = this.formatter.parse(control.value);

        const invalidDate = !parsed || !this.calendar.isValid(NgbDate.from(parsed));
        if (invalidDate) {
          return { 'invalid-date': true };
        }

        if (minDate && this.minDate) {
          if (NgbDate.from(parsed)?.before(jsDateToNgbDate(this.minDate))) {
            return { 'min-date': true };
          }
        }
      }

      return null;
    };
  };

  onFocus(target: 'from' | 'to') {
    this.focussedInput$$.next(target);
  }

  get minDateAsNgbDateStruct() {
    return this.minDate ? jsDateToNgbDate(this.minDate) : null;
  }
}

type FormValue = Partial<ReturnType<RangePickerComponent['rangeGroup']['getRawValue']>>;
