<div class="avatar-container">
  @if (pictureImg$ | async; as img) {
    <div
      class="person__avatar person__avatar--image"
      [ngStyle]="{ 'background-image': 'url(' + img + ')' }"
      (error)="onImgNotLoaded()"
      [hidden]="imgNotLoaded === true"
    ></div>
  } @else {
    @if (icon) {
      <div class="person__avatar person__avatar--icon">
        <fa-icon [icon]="icon" />
      </div>
    } @else {
      <div class="person__avatar person__avatar--initials">
        {{ (fullName | formatInitials) || '?' }}
      </div>
    }
  }
</div>
@if (fullNameVisible) {
  <div class="text-container" [class.user-unknown]="!fullName">
    @if (fullName) {
      @if (fullnameRef?.template; as template) {
        <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ $implicit: formattedFullName }" />
      } @else {
        {{ formattedFullName }}
      }
    } @else {
      @if (noFullnameRef?.template; as template) {
        <ng-container [ngTemplateOutlet]="template" />
      } @else {
        {{ noFullNameText }}
      }
    }
  </div>
}
