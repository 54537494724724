@if ((control.touched || control.dirty) && errors.length > 0) {
  @switch (hasCustomErrorMessage()) {
    @case (true) {
      <span class="input-error">
        {{ customErrorMessage }}
      </span>
    }
    @default {
      <span class="input-error">
        {{ firstError() | i18next: firstErrorContext }}
      </span>
    }
  }
}
