<div class="card confirm-popover card--modal--{{ type }}" cdkTrapFocus>
  <div class="confirm-popover__question">
    <fa-icon [icon]="icon" size="lg" class="confirm-popover__icon text-{{ type }}" />
    <div class="capitalize" [innerHTML]="confirmText | nl2br"></div>
  </div>
  @if (confirmBody) {
    <p [innerHTML]="confirmBody | nl2br"></p>
  }
  <div class="confirm-popover__controls">
    <button nxh-button buttonType="subtle-button" buttonStatus="neutral" (click)="cancel()" [disabled]="busy">
      {{ 'cancel' | i18next }}
    </button>
    <button #btn class="btn btn-{{ type }}" (click)="confirm()" [disabled]="busy">
      @if (busy) {
        <fa-icon class="spinner" animation="spin" icon="sync" />
      }
      {{ buttonText | i18next }}
    </button>
  </div>
</div>
