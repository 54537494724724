<div class="tiny-loading">
  @switch (status$ | async) {
    @case ('loading') {
      <div class="page-loading-indicator">
        <div class="page-loading-indicator__container">
          <fa-icon icon="spinner" animation="spin" />
          <span>{{ loadingMessage }}</span>
        </div>
      </div>
    }
    @case ('empty') {
      <div>
        @if (!emptyStateTemplate) {
          <div class="empty__title">
            {{ emptyMessage }}
          </div>
        } @else {
          @if (emptyStateTemplate) {
            <ng-container [ngTemplateOutlet]="emptyStateTemplate" />
          }
        }
      </div>
    }
    @case ('error') {
      <div class="page-loading-error">
        @if (!errorStateTemplate) {
          <div class="timeout__title">
            <fa-icon icon="hexagon-exclamation" />
            <span>{{ errorMessage }}</span>
          </div>
        } @else {
          <div class="timeout__title">
            <fa-icon icon="hexagon-exclamation" />
            <span>
              @if (errorStateTemplate) {
                <ng-container [ngTemplateOutlet]="errorStateTemplate" />
              }
            </span>
          </div>
        }
      </div>
    }
    @case ('warning') {
      <div class="page-loading-error">
        @if (!warningStateTemplate) {
          <div class="warning__title">
            <fa-icon icon="triangle-exclamation" />
            <span>{{ 'general-timeout-warning-title' | i18next }}</span>
          </div>
        } @else {
          <div class="warning__title">
            <fa-icon icon="triangle-exclamation" />
            <span>
              @if (warningStateTemplate) {
                <ng-container [ngTemplateOutlet]="warningStateTemplate" />
              }
            </span>
          </div>
        }
      </div>
    }
    @case ('success') {
      <div class="page-loading-error">
        @if (!successStateTemplate) {
          <div class="success__title">
            <fa-icon icon="check-circle" />
            <span>{{ 'general-timeout-success-title' | i18next }}</span>
          </div>
        } @else {
          <div class="success__title">
            <fa-icon icon="check-circle" />
            <span>
              @if (successStateTemplate) {
                <ng-container [ngTemplateOutlet]="successStateTemplate" />
              }
            </span>
          </div>
        }
      </div>
    }
  }
</div>
