<div [formGroup]="form" nxhToggle [pills]="pills">
  @for (option of options; track option; let index = $index) {
    <input
      type="checkbox"
      class="btn-check"
      [nxhDataTest]="'multi-toggle-list-item-' + index"
      [formControlName]="option.valueAsString"
      [id]="id + '_' + index"
      autocomplete="off"
    />
    <label
      class="btn btn-primary"
      nxhToggleOption
      [showCheckmark]="showCheckMarks"
      [class.active]="form.get(option.valueAsString)?.value"
      [class.disabled]="form.get(option.valueAsString)?.disabled"
      [for]="id + '_' + index"
    >
      @if (optionDirective?.template; as template) {
        <ng-container *ngTemplateOutlet="template; context: { $implicit: option }" />
      } @else {
        @if (option.label; as label) {
          <span>{{ label | i18next }}</span>
        }
      }
    </label>
  }
</div>
