<div class="empty-container" [class.empty--lg]="size === 'lg'">
  @if (isFilterOn$ | async) {
    <fa-icon class="empty-icon" icon="folder-magnifying-glass" [fixedWidth]="true" />
    <div class="no-items-found-label">
      {{ noItemsFoundLabel ? noItemsFoundLabel : ('_loading-states.no-items-found' | i18next) }}
    </div>
    <div class="change-filter">{{ '_loading-states.change-filter' | i18next }}</div>
  } @else {
    <fa-icon class="empty-icon" icon="folder-open" [fixedWidth]="true" />
    <div class="no-items-label">
      {{ label ? label : ('_loading-states.no-items' | i18next) }}
    </div>
    @if (description) {
      <div class="no-items-description">
        {{ description }}
      </div>
    }
    @if (createItemLabel && !disableCreateItem) {
      @if (createItemLink) {
        <button
          nxh-button
          buttonType="subtle-button"
          [routerLink]="createItemLink"
          [relativeTo]="createItemLinkRelativeTo"
          nxhDataTest="create-item-link"
        >
          <fa-icon icon="plus" />
          {{ createItemLabel }}
        </button>
      } @else {
        <button nxh-button buttonType="subtle-button" (click)="createItem.emit()" nxhDataTest="create-item-button">
          <fa-icon icon="plus" />
          {{ createItemLabel }}
        </button>
      }
    }
  }
</div>
