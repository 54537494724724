import { I18NextModule, ITranslationService } from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { cloneDeep } from 'lodash-es';
import { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector/index';
import { CookieService, moaprFunctionalCookieKeys } from '@nexuzhealth/shared-settings-data-access-cookies';
import { I18NextConfig } from './config';

const local_storage_lang_key = moaprFunctionalCookieKeys.nh_locize_i18nextlang;

export function appInit(
  i18next: ITranslationService,
  customConfig: I18NextConfig,
  cookieservice: CookieService,
  preferredLanguage?: string,
  namespacesConfig?: NamespacesConfig,
): Promise<any> {
  if (!namespacesConfig) {
    namespacesConfig = {
      ns: ['common', 'backend', 'ui-toolkit'],
      defaultNS: 'common',
      fallbackNS: ['common', 'backend', 'ui-toolkit'],
    };
  }

  const CookieServiceLanguageDetector: CustomDetector = {
    name: 'cookieServiceLanguageDetector',
    lookup(options: DetectorOptions): string | string[] | undefined {
      const storedLanguage = cookieservice.getFunctionalCookie(local_storage_lang_key);
      return storedLanguage ? storedLanguage : preferredLanguage;
    },
    cacheUserLanguage(lng: string, options: DetectorOptions): void {
      cookieservice.setFunctionalCookie(local_storage_lang_key, lng);
    },
  };

  // @see https://github.com/locize/i18next-locize-backend/blob/master/src/index.js
  // (for now?) we don't specify referenceLng or fallbackLng, as this might result in
  // loading all translation bundles twice
  const defaultConfig = {
    supportedLngs: ['en', 'nl', 'de', 'fr'],
    debug: false,
    returnEmptyString: false,
    fallbackLng: 'nl',
    interpolation: {
      format: I18NextModule.interpolationFormat((value, format) => {
        if (format === 'lowercaseFirstChar') {
          return lowercaseFirstChar(value);
        }
        return value;
      }),
    },
    ...namespacesConfig,
  };

  // store preferred language (as loaded from UserPreferences)...
  if (preferredLanguage) {
    cookieservice.setFunctionalCookie(local_storage_lang_key, preferredLanguage);
  }

  // ... and configure LanguageDetector to primarily fetch the language from localstorage
  const detectionConfig = {
    // see https://github.com/i18next/i18next-browser-languageDetector Adding own detection functionality
    order: ['cookieServiceLanguageDetector'],
    caches: [], // default config uses localStorage as cache, but we want to use the CookieService
  };

  const config = { ...defaultConfig, ...customConfig, detection: detectionConfig };

  // add our custom detector
  const languageDetector = new LanguageDetector().addDetector(CookieServiceLanguageDetector);

  return i18next
    .use(languageDetector)
    .use(HttpApi)
    .init(cloneDeep(config)) // deep clone, otherwise i18next will dirten the settings
    .then((evt) => {
      if (evt && evt.err) {
        // this doesn't work??
        console.error('Error while loading i18next', evt.err);
      }
    });
}

export interface NamespacesConfig {
  ns: string[];
  defaultNS?: string;
  fallbackNS?: string | string[];
}

function lowercaseFirstChar(value: string) {
  // don't lowercase first char of values like OCMW, RIZIV
  const isAbbrev = /^[A-Z][A-Z]/.test(value);
  if (isAbbrev) {
    return value;
  }
  return value ? value[0]?.toLowerCase() + value.substring(1) : '';
}
