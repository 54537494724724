<!--ng-option--loading for backwards compatibility, check _selects.scss for more details-->
<div class="ng-option ng-option--loading">
  <div class="ng-option-content">
    <fa-icon icon="spinner" animation="spin" />
    @if (loadingText) {
      <span>{{ loadingText }}</span>
    } @else {
      {{ '_ng-select.loading-text' | i18next }}
    }
  </div>
</div>
