import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnInit,
  Renderer2,
  TemplateRef,
} from '@angular/core';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { BehaviorSubject, Observable, pairwise, timer } from 'rxjs';
import { filterNilValue } from '@datorama/akita';
import { debounce, startWith } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EmptyComponent } from './empty/empty.component';
import { ErrorComponent } from './error/error.component';
import { LoadingComponent } from './loading/loading.component';
import { LoadingStatus, toLoadingStatus } from './loading-states.model';

export type LoadingStatesPosition = 'center' | 'above-center';

@Component({
  selector: 'nxh-loading-states',
  template: `
    @switch (loadingState$ | async) {
      @case ('loading') {
        @if (loadingTemplate) {
          <ng-container [ngTemplateOutlet]="loadingTemplate" />
        } @else {
          <nxh-loading />
        }
      }
      @case ('empty') {
        @if (emptyTemplate) {
          <ng-container [ngTemplateOutlet]="emptyTemplate" />
        } @else {
          <nxh-empty />
        }
      }
      @case ('error') {
        @if (errorTemplate) {
          <ng-container [ngTemplateOutlet]="errorTemplate" />
        } @else {
          <nxh-error />
        }
      }
    }
  `,
  styles: [
    `
      :host {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        /*width: 100%;*/
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        /*todo why is this necessary?*/
        align-self: center;
      }

      :host.loading-states--above-center {
        /*when inside page-body the loading states should be positioned higher on the page*/
        bottom: 25%;
      }

      :host.loaded {
        display: none;
      }
    `,
  ],
  standalone: true,
  imports: [EmptyComponent, ErrorComponent, LoadingComponent, NgTemplateOutlet, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingStatesComponent implements OnInit {
  @Input() emptyTemplate?: TemplateRef<any>;
  @Input() errorTemplate?: TemplateRef<any>;
  @Input() loadingTemplate?: TemplateRef<any>;

  /**
   * Indication where to position the loading spinner, empty component, error component. Typically when inside a
   * 'page body' these components will be positioned slightly 'above-center'.
   */
  @Input() position: LoadingStatesPosition = 'center';

  /**
   * Set to true to wait couple of milliseconds before showing the loading state. Default is false because currently
   * this might show weird behavior in combination with the filterOn state on tables.
   */
  @Input() debounceLoading = false;

  private loadingState$$ = new BehaviorSubject<LoadingStatus | null>(null);
  loadingState$!: Observable<LoadingStatus>;

  private renderer = inject(Renderer2);
  private elementRef = inject(ElementRef);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    if (this.debounceLoading) {
      this.loadingState$ = this.loadingState$$.pipe(
        filterNilValue(),
        debounce((loadingState) => (loadingState === 'loading' ? timer(300) : timer(0))),
      );
    } else {
      this.loadingState$ = this.loadingState$$.asObservable().pipe(filterNilValue());
    }

    // Not using HostBinding to prevent inconsistent state when using debounceLoading
    this.loadingState$
      .pipe(startWith(null), pairwise(), takeUntilDestroyed(this.destroyRef))
      .subscribe(([prev, curr]) => {
        if (prev) {
          this.renderer.removeClass(this.elementRef.nativeElement, prev);
        }
        if (curr) {
          this.renderer.addClass(this.elementRef.nativeElement, curr);
        }
      });
  }

  @Input({ transform: toLoadingStatus }) set loadingState(loadingState: LoadingStatus | null) {
    this.loadingState$$.next(loadingState);
  }

  @HostBinding('class.loading-states--above-center') get aboveCenter() {
    return this.position === 'above-center';
  }
}
