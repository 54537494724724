import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, TemplateRef } from '@angular/core';
import {
  EmptyDefDirective,
  ErrorDefDirective,
  LoadingDefDirective,
  LoadingStatus,
  toLoadingStatus,
} from '@nexuzhealth/shared-ui-toolkit/loading2';
import { PageLoaderStatus } from '@nexuzhealth/shared-ui-toolkit/loading-states/page-loader';

@Component({
  selector: 'nxh-modal-body',
  templateUrl: './modal-body.component.html',
  styleUrls: ['./modal-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalBodyComponent {
  @HostBinding('class')
  _hostClasses = 'modal-body';
  @HostBinding('class.nxh-modal-body-no-top-padding')
  _noTopPadding!: boolean;

  @HostBinding('class.nxh-modal-body-no-padding')
  @Input()
  noPadding = false;

  @HostBinding('class.nxh-modal-body--fit-content')
  @Input()
  fitContent = false;

  @Input({ transform: toLoadingStatus }) loadingState?: LoadingStatus | null = null;

  @ContentChild(EmptyDefDirective, { read: TemplateRef, descendants: false })
  protected emptyTemplate?: TemplateRef<any>;
  @ContentChild(ErrorDefDirective, { read: TemplateRef, descendants: false })
  protected errorTemplate?: TemplateRef<any>;
  @ContentChild(LoadingDefDirective, { read: TemplateRef, descendants: false })
  protected loadingTemplate?: TemplateRef<any>;

  @HostBinding('class.nxh-modal-body--loaded')
  get loaded() {
    return !this.loadingState || this.loadingState === PageLoaderStatus.loaded;
  }
}
