import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { I18NextModule } from 'angular-i18next';
import { DOCUMENT } from '@angular/common';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { StackModule } from '@nexuzhealth/shared-ui-toolkit/stack';

@Component({
  selector: 'nxh-error',
  template: `
    <div class="error-container">
      <fa-icon class="error-icon" [icon]="['fas', 'hexagon-exclamation']" [fixedWidth]="true" />
      <nxh-stack direction="col" alignItems="center" gap="none">
        <div class="error-label">{{ label ? label : ('_loading-states.error' | i18next) }}</div>
        <ng-content select="[nxhDescriptionDef]" />
        @if (!disableReload) {
          <button nxh-button buttonType="subtle-button" (click)="reload()" nxhDataTest="reload-button">
            <fa-icon icon="redo-alt" />
            <span>{{ reloadLabel ? reloadLabel : ('reload' | i18next) }}</span>
          </button>
        }
      </nxh-stack>
    </div>
  `,
  styleUrls: ['./error.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [I18NextModule, IconsModule, ButtonModule, SharedTechFeatureE2eModule, StackModule],
})
export class ErrorComponent {
  /**
   * Custom label shown when there is an error
   */
  @Input() label?: string;

  /**
   * Custom label for the "reload" button ("Reload the page to try again")
   */
  @Input() reloadLabel?: string;

  /**
   * Disables showing the reload button. Defaults to false.
   */
  @Input() disableReload = false;

  /**
   * Custom function that needs to be performed when the user clicks the reload button. Defaults to a page reload
   */
  @Input() onReload?: () => void;

  private document = inject(DOCUMENT);

  reload() {
    if (this.onReload) {
      this.onReload();
    } else {
      this.document.location.reload();
    }
  }
}
