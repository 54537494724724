<div class="breadcrumb__container">
  @for (bc of breadcrumbs$ | async; track bc; let last = $last) {
    @if (bc.label) {
      <div class="d-inline-block {{ bc.classnames }}">
        @switch (last) {
          @case (true) {
            <span class="breadcrumb__item--span">{{ bc.label }}</span>
          }
          @default {
            <a class="breadcrumb__item--link" [routerLink]="bc.extraUrl ? bc.extraUrl : bc.url">{{ bc.label }}</a>
            @if (!last) {
              <span class="breadcrumb__separator">/</span>
            }
          }
        }
      </div>
    }
  }
</div>
