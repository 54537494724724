<button
  #button
  nxh-button
  [buttonStatus]="type"
  type="submit"
  [loading]="submitting$ | async"
  [disabled]="disabled"
  [outline]="outline"
  [attr.form]="formId"
  nxhDataTest="submit-button"
  data-testid="submit-button"
>
  <div class="content-container" #ref><ng-content /></div>
  @if (!ref.hasChildNodes()) {
    <fa-icon [icon]="icon" /><span>{{ label | i18next }}</span>
  }
</button>
